import React from 'react';
import {
  useEditLessonTheoryMutation,
  useGetLessonTheoriesQuery,
  useGetLessonTheoryDetailsQuery
} from '../../../store/api/endpoints/lessonTheory';
import { Button, Card, Col, Divider, Empty, Row, Space, Spin, Tag, Typography } from 'antd';
import { filter, head, includes, isEmpty, map, not, pipe } from 'ramda';
import TheoryForm from '../TheoryForm';
import PublicationStatusForm from '../PublicationStatusForm';
import CreateLessonTheoryDrawer from '../CreateLessonTheoryDrawer';
import MainTheoryForm from '../MainTheoryForm';
import {
  closeMainLessonTheoryForm,
  toggleMainLessonTheoryForm,
  useMainLessonTheoryUi
} from '../../../store/slicers/lessonTheorySlice';
import { LANGUAGES } from '../../../constants';
import AvailableLanguagesView from '../../shared/AvailableLanguagesView';
import { FiEdit2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import TheoryTranslates from '../TheoryTranslates';
import LessonVideos from '../../lessons/LessonVideos';

const TheoryContainer = ({ lessonId }) => {
  const ui = useMainLessonTheoryUi();
  const dispatch = useDispatch();

  const [
    editLessonTheory,
    { isLoading: isEditing },
  ] = useEditLessonTheoryMutation();

  const {
    data: lessonTheoryData,
    isFetching,
    isLoading,
  } = useGetLessonTheoriesQuery({ lesson: lessonId }, { skip: !lessonId });

  const hasTheory = (lessonTheoryData && lessonTheoryData.count > 0);

  const theoryData = hasTheory ? head(lessonTheoryData.results) : {};

  const {
    data: theoryDetails,
    isLoading: isTheoryDetailsLoading,
  } = useGetLessonTheoryDetailsQuery(theoryData.id, { skip: !hasTheory });

  const handleEdit = async (values) => {
    try {
      await editLessonTheory({ theoryId: theoryDetails.id, data: values }).unwrap();
      dispatch(closeMainLessonTheoryForm());
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoading || isTheoryDetailsLoading) return <Spin spinning />
  if (!hasTheory) return (
    <div style={{ textAlign: 'center' }}>
      <Empty description="Теория пока не создана" style={{ marginBottom: 20 }} />
      <CreateLessonTheoryDrawer lessonId={lessonId} />
    </div>
  );

  if (!theoryDetails) return <div>Запрос деталей теории по {theoryData.id} ничего не вернул</div>

  return (
    <Row gutter={12}>
      <Col md={8}>
        <Card size="small">
          <Row justify="space-between">
            <Col>
              <PublicationStatusForm
                theoryId={theoryData.id}
                isPublished={theoryData.isPublished}
              />
              <div style={{ marginBottom: 20 }}>
                <Typography.Text type={theoryData.isPublishedTotal ? 'success' : 'danger'}>{theoryData.isPublishedTotal ? 'Доступен для пользователя' : 'Недоступен для пользователя'}</Typography.Text>
              </div>
            </Col>
            <Col>
              <Button
                icon={<FiEdit2 />}
                type="text"
                onClick={() => dispatch(toggleMainLessonTheoryForm())}
              />
            </Col>
          </Row>
          {
            ui.isEditing
              ? (
                <MainTheoryForm
                  onSubmit={handleEdit}
                  loading={false}
                  initialValues={theoryData}
                />
              ) : (
                <Space direction="vertical">
                  <AvailableLanguagesView languages={theoryData.availableLanguages} />
                </Space>
              )
          }
        </Card>
      </Col>

      <Col md={8}>
        <Card size="small">
          <LessonVideos
            languageKey="ru"
            theoryId={theoryDetails.id}
          />
        </Card>
      </Col>

      <Col md={8}>
        <Card size="small">
          <LessonVideos
            languageKey="uz"
            theoryId={theoryDetails.id}
          />
        </Card>
      </Col>

      <Divider />

      <Col md={12}>
        <Card size="small">
          <TheoryTranslates
            languageKey="ru"
            title="Конспект на Русском"
            theoryId={theoryDetails.id}
          />
        </Card>
      </Col>
      <Col md={12}>
        <Card size="small">
          <TheoryTranslates
            languageKey="uz"
            title="Конспект на Узбекском"
            theoryId={theoryDetails.id}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default TheoryContainer;
