import React, { useState } from 'react';
import cn from 'classnames';

import styles from './LessonCreationFormDrawer.module.scss';
import { Button, Drawer } from 'antd';
import LessonMainForm from '../LessonMainForm';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { useCreateLessonMutation } from '../../../store/api/endpoints/lessons';

const LessonCreationFormDrawer = ({ moduleId }) => {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();

  const [
    createLesson,
    { isLoading: isCreating },
  ] = useCreateLessonMutation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (data) => {
    try {
      const operatedData = await createLesson({
        ...data,
        module: moduleId,
      }).unwrap();
      navigate(routes.lessons(operatedData.id));
    } catch (e) {

    }
  };

  const initialValues = {
    placeInOrder: 1,
    availableLanguages: [],
    isFree: false,
    sysName: '',
    slug: '',
    module: null,
  };

  return (
    <>
      <Button type="primary" onClick={handleOpen}>
        Создать урок
      </Button>
      <Drawer
        open={open}
        onClose={handleClose}
        destroyOnClose
        width={600}
      >
        <LessonMainForm onSubmit={handleSubmit} loading={isCreating} initialValues={initialValues} />
      </Drawer>
    </>
  );
};

export default LessonCreationFormDrawer;
