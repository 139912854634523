import React from 'react';
import cn from 'classnames';

import styles from './LessonTranslates.module.scss';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import { Button, Col, Empty, Row, Space, Spin, Typography } from 'antd';
import { useAppendTranslateToLessonMutation, useGetLessonDetailsQuery } from '../../../store/api/endpoints/lessons';
import {
  closeLessonTranslatesForm,
  toggleLessonTranslatesForm,
  toggleMainLessonDataForm,
  useLessonTranslatesUi
} from '../../../store/slicers/lessonsSlice';
import { FiEdit2, FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

const LessonTranslates = ({ lessonId, languageKey, title }) => {
  const ui = useLessonTranslatesUi(languageKey);

  const dispatch = useDispatch();

  const {
    data: lesson,
    isLoading,
  } = useGetLessonDetailsQuery(lessonId);

  const [
    appendTranslate,
    { isLoading: isAppendingTranslate },
  ] = useAppendTranslateToLessonMutation();

  const rootInitialValues = {
    name: '',
    description: '',
  };

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: lesson?.translates?.[languageKey] ?? rootInitialValues,
    values: lesson?.translates?.[languageKey] ?? rootInitialValues,
  });

  const handleSubmit = async (data) => {
    try {
      await appendTranslate({ id: lessonId, languageKey, data }).unwrap();
      dispatch(closeLessonTranslatesForm(languageKey));
    } catch (e) {
      console.log(e);
    }
  };

  const render = () => {
    if (!lessonId) return <Empty description="Сначала нужно создать модуль" />;
    if (isLoading) return <Spin spinning />;
    if (!lesson) return <div>Запрос по уроку = {lessonId} ничего не вернул</div>

    const currentData = lesson.translates[languageKey];

    if (ui.isEditing) return (
      <form onSubmit={submit(handleSubmit)}>
        <InputController name="name" control={control} label="Название" disabled={isAppendingTranslate} />
        <InputController name="description" control={control} label="Описание" disabled={isAppendingTranslate} />
        <Button block htmlType="submit" type="primary" loading={isAppendingTranslate}>
          Сохранить
        </Button>
      </form>
    );

    if (!currentData) {
      return <Empty description="Поля еще не заполнены" />
    }

    return (
      <div>
        <Space direction="vertical">
          <div>
            <Typography.Text>Название</Typography.Text> <br/>
            <Typography.Text strong>{currentData.name || '-'}</Typography.Text>
          </div>
          <div>
            <Typography.Text>Описание</Typography.Text> <br/>
            <Typography.Text strong>{currentData.description || '-'}</Typography.Text>
          </div>
        </Space>
      </div>
    )
  }

  return (
    <div>
      <Row>
        <Col>
          <Typography.Title style={{ marginTop: 0 }} level={4}>
            {title}
          </Typography.Title>
        </Col>
        <Col>
          <Button
            icon={ui.isEditing ? <FiX /> : <FiEdit2 />}
            type="text"
            onClick={() => dispatch(toggleLessonTranslatesForm(languageKey))}
          />
        </Col>
      </Row>
      <div style={{ marginTop: 20 }}>
        {render()}
      </div>
    </div>
  )
};

export default LessonTranslates;
