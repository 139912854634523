import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getModules: build.query({
      query: (params) => ({
        url: routes.modules(),
        params
      }),
      providesTags: ['MODULES'],
    }),
    getModuleDetails: build.query({
      query: (id) => ({
        url: routes.modules(id),
      }),
      providesTags: ['MODULE_DETAILS'],
    }),
    editModule: build.mutation({
      query: ({ id, data }) => ({
        url: routes.modules(id),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['MODULE_DETAILS', 'MODULES'] }),
    }),
    createModule: build.mutation({
      query: (data) => ({
        url: routes.modules(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['MODULES'] }),
    }),
    appendTranslateToModule: build.mutation({
      query: ({ moduleId, languageKey, data }) => ({
        url: routes.moduleTranslates(moduleId, languageKey),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['MODULE_DETAILS'] }),
    }),
    publishModule: build.mutation({
      query: (id) => ({
        url: routes.modulePublication(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['MODULE_DETAILS', 'MODULES'] }),
    }),
    unpublishModule: build.mutation({
      query: (id) => ({
        url: routes.modulePublication(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['MODULE_DETAILS', 'MODULES'] }),
    }),
  }),
});

export const {
  useGetModulesQuery,
  useGetModuleDetailsQuery,
  useEditModuleMutation,
  useCreateModuleMutation,
  useAppendTranslateToModuleMutation,
  usePublishModuleMutation,
  useUnpublishModuleMutation,
} = mainApi;
