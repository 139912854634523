import React from 'react';
import cn from 'classnames';

import styles from './TariffPublicationStatus.module.scss';
import {
  useGetTariffDetailsQuery,
  usePublishTariffMutation,
  useUnpublishTariffMutation
} from '../../../store/api/endpoints/tariffs';
import { Button, Flex, Space, Spin, Typography } from 'antd';

const TariffPublicationStatus = ({ tariffId }) => {
  const {
    data: tariff,
    isLoading: isTariffLoading,
  } = useGetTariffDetailsQuery(tariffId);

  const [
    publishTariff,
    { isLoading: isPublishing },
  ] = usePublishTariffMutation();

  const [
    unpublishTariff,
    { isLoading: isUnpublishing },
  ] = useUnpublishTariffMutation();

  const handleClickPublish = async () => {
    try {
      await publishTariff(tariffId).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  const handleClickUnpublish = async () => {
    try {
      await unpublishTariff(tariffId).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  const style = { marginTop: 0 };

  if (isTariffLoading) return <Spin spinning />
  if (!tariff) return null;

  return tariff.isPublished
    ? (
      <Flex gap={10}>
        <Typography.Title type="success" level={3} style={style}>Опубликован</Typography.Title>
        <Button type="primary" danger onClick={handleClickUnpublish} loading={isUnpublishing}>
          Снять публикацию
        </Button>
      </Flex>
    ) : (
      <Flex gap={10}>
        <Typography.Title type="danger" level={4} style={style}>Не опубликован</Typography.Title>
        <Button type="primary" onClick={handleClickPublish} loading={isPublishing}>
          Опубликовать
        </Button>
      </Flex>
    )
};

export default TariffPublicationStatus;
