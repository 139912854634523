import { joinExisting } from '../utils';

const routes = {
  root: () => '/',
  app: () => '/app',
  modules: (id) => joinExisting(['/app/modules', id]),
  tags: (id) => joinExisting(['/app/tags', id]),
  lessons: (id) => joinExisting(['/app/lessons', id]),
  users: (id) => joinExisting(['/app/users', id]),
  tariffs: (id) => joinExisting(['/app/tariffs', id]),
  repoCredentials: (id) => joinExisting(['/app/repo-credentials', id]),
  discussions: (id) => joinExisting(['/app/discussions', id]),
};

export const navs = [
  {
    key: routes.modules(),
    label: 'Модули',
  },
  {
    key: routes.tags(),
    label: 'Тэги',
  },
  {
    key: routes.users(),
    label: 'Пользователи',
  },
  {
    key: routes.tariffs(),
    label: 'Тарифы',
  },
  {
    key: routes.repoCredentials(),
    label: 'Креденшелы реп',
  },
  {
    key: routes.discussions(),
    label: 'Обсуждения',
  },
];

export default routes;
