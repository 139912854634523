import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';
import { without } from 'ramda';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: (params) => ({
        url: routes.users(),
        params,
      }),
      providesTags: ['USERS'],
    }),
    getUserDetails: build.query({
      query: (id) => ({
        url: routes.users(id),
      }),
      providesTags: ['USER_DETAILS'],
    }),
    createUser: build.mutation({
      query: (data) => ({
        url: routes.users(),
        data,
        method: 'POST',
      }),
    }),
    editUser: build.mutation({
      query: ({ userId, data }) => ({
        url: routes.users(userId),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['USER_DETAILS'] })
    }),
    changeUserPassword: build.mutation({
      query: ({ userId, data }) => ({
        url: routes.usersPassword(userId),
        data,
        method: 'PUT',
      }),
    }),
    addPermission: build.mutation({
      query: ({ userId, data }) => ({
        url: routes.usersPermission(userId),
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['USER_DETAILS'] })
    }),
    removePermission: build.mutation({
      query: ({ userId, data }) => ({
        url: routes.usersPermission(userId),
        data,
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['USER_DETAILS'] })
    }),

    banUser: build.mutation({
      query: ({ userId, data }) => ({
        url: routes.usersBan(userId),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['USER_DETAILS'] })
    }),
    unbanUser: build.mutation({
      query: (userId) => ({
        url: routes.usersUnban(userId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['USER_DETAILS'] })
    }),
  }),
});

export const {
  useCreateUserMutation,
  useEditUserMutation,
  useChangeUserPasswordMutation,
  useGetUsersQuery,
  useGetUserDetailsQuery,
  useAddPermissionMutation,
  useRemovePermissionMutation,
  useBanUserMutation,
  useUnbanUserMutation,
} = mainApi;
