import React from 'react';
import cn from 'classnames';

import styles from './UserSubscriptions.module.scss';
import { useGetSubscriptionsQuery } from '../../../store/api/endpoints/subscriptions';
import useQueryString from '../../../hooks/useQueryString';
import { useGetTariffsQuery } from '../../../store/api/endpoints/tariffs';
import TableWrapper from '../../shared/TableWrapper';
import { Divider, Empty, Space, Spin, Typography } from 'antd';
import { formatDate } from '../../../utils';
import CancelUserSubscription from '../CancelUserSubscription';

const UserSubscriptions = ({ userId }) => {
  const { page = 1, pageSize = 50, ...restQuery } = useQueryString();
  // TODO: Нужно фильтровать по этому пользователю
  const {
    data: subscriptions,
    isLoading: isSubscriptionsLoading,
  } = useGetSubscriptionsQuery({ page, pageSize, /*user: userId,*/ });

  const {
    data: tariffs,
    isLoading: isTariffsLoading,
  } = useGetTariffsQuery({ page: -1 });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Тариф',
      dataIndex: 'tariff',
      key: 'tariff',
      render: (tariff) => (
        <Space>
          <div>{tariff.sysName}</div>
          <div>{tariff.price}</div>
          <div>{tariff.daysCount}</div>
        </Space>
      )
    },
    {
      title: 'Состояние',
      dataIndex: 'state',
      key: 'state',
      render: (state) => state.sysName,
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'paidTime',
      key: 'paidTime',
      render: (paidTime) => formatDate(paidTime, true),
    },
    {
      title: 'Дата начала',
      dataIndex: 'startedDate',
      key: 'startedDate',
      render: (startedDate) => formatDate(startedDate),
    },
    {
      title: 'Дата окончания',
      dataIndex: 'finishedDate',
      key: 'finishedDate',
      render: (finishedDate) => formatDate(finishedDate),
    },
  ];

  if (isSubscriptionsLoading) return <Spin spinning />
  if (!subscriptions) return <Empty description="Ничего не было получено" />

  return (
    <TableWrapper
      data={subscriptions.results}
      columns={columns}
      total={subscriptions.count}
      rowKey="id"
      expandable={{
        expandedRowRender: (record) => (
          <div>
            <CancelUserSubscription subscriptionId={record.id} />
            <Divider />
            <Space size={40}>
              {
                record.adminCreate && (
                  <div>
                    <Typography.Text type="secondary">Создал админ</Typography.Text> <br/>
                    <Typography.Text bold>{record.adminCreate}</Typography.Text> <br/>
                  </div>
                )
              }
              {
                record.adminReject && (
                  <div>
                    <Typography.Text type="secondary">Отменил админ</Typography.Text> <br/>
                    <Typography.Text bold>{record.adminReject}</Typography.Text> <br/>
                  </div>
                )
              }
              {
                record.rejectedReason && (
                  <div>
                    <Typography.Text type="secondary">Причина отмены</Typography.Text> <br/>
                    <Typography.Text bold>{record.rejectedReason}</Typography.Text> <br/>
                  </div>
                )
              }
              {
                record.rejectedTime && (
                  <div>
                    <Typography.Text type="secondary">Время отмены</Typography.Text> <br/>
                    <Typography.Text bold>{formatDate(record.rejectedTime, true)}</Typography.Text> <br/>
                  </div>
                )
              }
            </Space>
          </div>
        )
      }}
    />
  );
};

export default UserSubscriptions;
