import React from 'react';
import { useController } from 'react-hook-form';
import { Input, Typography } from 'antd';

const InputController = ({
  control,
  name,
  label,
  type = 'text',
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
  });

  const inputComponents = {
    text: Input,
    password: Input.Password,
    textarea: Input.TextArea,
  };

  const InputComponent = inputComponents[type];

  return (
     <div
       style={{
         position: 'relative',
         paddingBottom: 20,
       }}
     >
       <Typography.Text type={fieldState.error ? 'danger' : 'secondary'}>
         {label}
       </Typography.Text>
       <InputComponent
         value={field.value}
         onChange={field.onChange}
         status={fieldState.error ? 'error' : null}
         {...props}
       />
       <div
         style={{
           textAlign: 'right',
           position: 'absolute',
           bottom: 0,
           left: 0,
           right: 0,
         }}
       >
         <Typography.Text type="danger">
           {fieldState.error ? fieldState.error.message : null}
         </Typography.Text>
       </div>
     </div>
  );
};

export default InputController;
