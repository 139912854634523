import React from 'react';
import cn from 'classnames';

import styles from './TheoryTranslates.module.scss';
import {
  closeLessonTheoryTranslatesForm,
  toggleLessonTheoryTranslatesForm,
  useLessonTheoryTranslatesUi
} from '../../../store/slicers/lessonTheorySlice';
import MarkdownInputController from '../../shared/controllers/MarkdownInputController';
import { useForm } from 'react-hook-form';
import {
  useAppendTranslatesToLessonTheoryMutation,
  useGetLessonTheoryDetailsQuery
} from '../../../store/api/endpoints/lessonTheory';
import { Button, Col, Empty, Row, Spin, Typography } from 'antd';
import { FiEdit2 } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import MDEditor from '@uiw/react-md-editor';

const TheoryTranslates = ({
  languageKey,
  theoryId,
  title,
}) => {
  const ui = useLessonTheoryTranslatesUi(languageKey);
  const dispatch = useDispatch();

  const [
    appendTranslateToTheory,
    { isLoading: isAppendingTranslates },
  ] = useAppendTranslatesToLessonTheoryMutation();

  const {
    data: theoryDetails,
    isLoading: isTheoryDetailsLoading,
  } = useGetLessonTheoryDetailsQuery(theoryId, { skip: !theoryId });

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: theoryDetails?.translates?.[languageKey],
  });

  const handleSubmit = async (values) => {
    try {
      await appendTranslateToTheory({ theoryId, languageKey, data: values }).unwrap();
      dispatch(closeLessonTheoryTranslatesForm(languageKey));
    } catch (e) {
      console.log(e);
    }
  };

  const render = () => {
    if (isTheoryDetailsLoading) return <Spin spinning />
    if (!theoryDetails) return <div>Запрос по теории = {theoryId} ничего не вернул</div>

    const currentData = theoryDetails.translates[languageKey];

    if (ui.isEditing) return (
      <form onSubmit={submit(handleSubmit)}>
        <MarkdownInputController
          name="content"
          control={control}
          height={400}
        />
        <Button block htmlType="submit" type="primary">
          Сохранить
        </Button>
      </form>
    );

    if (!currentData) return <Empty description="Поля еще не заполнены" />

    return (
      <MDEditor
        theme="light"
        value={currentData.content}
        fullscreen={false}
        overflow={false}
        preview="preview"
        hideToolbar
      />
    )
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4} style={{ marginTop: 0 }}>{title}</Typography.Title>
        </Col>
        <Col>
          <Button
            icon={<FiEdit2 />}
            type="text"
            onClick={() => dispatch(toggleLessonTheoryTranslatesForm(languageKey))}
          />
        </Col>
      </Row>
      {render()}
    </div>
  );
};

export default TheoryTranslates;
