import React, { useState } from 'react';
import { useGetLessonQuestionsQuery } from '../../../store/api/endpoints/lessonTests';
import { Flex, List, Typography } from 'antd';
import CreateQuestionFormDrawer from '../CreateQuestionFormDrawer';
import QuestionPublicationStatusForm from '../QuestionPublicationStatusForm';
import EditQuestionFormDrawer from '../EditQuestionFormDrawer';
import DeleteQuestion from '../DeleteQuestion';
import AnswersList from '../AnswersList';

const TestList = ({ lessonId }) => {
  const [activeQuestion, setActiveQuestion] = useState(null);

  const {
    data: questionList,
    isFetching: isQuestionListFetching,
  } = useGetLessonQuestionsQuery({ lesson: lessonId, page: -1 }, { skip: !lessonId });

  return (
    <Flex gap={12}>
      <div style={{ width: '50%' }}>
        <CreateQuestionFormDrawer lessonId={lessonId} />
        <List
          loading={isQuestionListFetching}
          dataSource={questionList?.results}
          header="Список вопросов"
          bordered
          size="small"
          itemLayout="vertical"
          style={{ marginTop: 12 }}
          renderItem={(item) => (
            <List.Item
              extra={
                <QuestionPublicationStatusForm
                  isPublished={item.isPublished}
                  questionId={item.id}
                />
              }
              actions={[
                <EditQuestionFormDrawer questionId={item.id} />,
                <DeleteQuestion questionId={item.id} />
              ]}
            >
              <List.Item.Meta
                avatar={item.placeInOrder}
                title={item.sysName}
                description={
                  <>
                    <Typography.Text copyable={{ tooltips: false, text: item.id }}>ID: {item.id}</Typography.Text>
                    <div>{item.isPublished ? 'Опубликован' : 'Не опубликован'}</div>
                  </>
                }
                onClick={() => {
                  setActiveQuestion(item);
                }}
                style={{ cursor: 'pointer' }}
              />
            </List.Item>
          )}
        />
      </div>

      <div style={{ width: '50%' }}>
        <AnswersList
          question={activeQuestion}
        />
      </div>
    </Flex>
  );
};

export default TestList;
