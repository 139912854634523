import React from 'react';
import { Flex, Typography } from 'antd';
import CreateTariffDrawer from '../../../containers/tariffs/CreateTariffDrawer';
import TariffList from '../../../containers/tariffs/TariffList';

const Tariffs = () => {

  return (
    <div>
      <Flex justify="space-between">
        <Typography.Title level={3} style={{ marginTop: 0 }}>Тарифы</Typography.Title>
        <CreateTariffDrawer />
      </Flex>
      <div className="paper">
        <TariffList />
      </div>
    </div>
  );
};

export default Tariffs;
