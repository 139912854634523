import React from 'react';
import cn from 'classnames';

import styles from './LessonVideos.module.scss';
import { Button, Col, Empty, Row, Spin, Typography } from 'antd';
import ReactPlayer from 'react-player';
import { useForm } from 'react-hook-form';
import { FiEdit2 } from 'react-icons/fi';
import {
  closeLessonTheoryVideoForm,
  toggleLessonTheoryVideoForm,
  useLessonTheoryTranslatesUi
} from '../../../store/slicers/lessonTheorySlice';
import { useDispatch } from 'react-redux';
import { useAddVideoToTheoryMutation, useGetLessonTheoryDetailsQuery } from '../../../store/api/endpoints/lessonTheory';
import InputController from '../../shared/controllers/InputController';
import VideoPickerController from '../../shared/controllers/VideoPickerController';
import ApiVideoPlayer from '@api.video/react-player';
import { parseQuery } from '../../../utils';

const LessonVideos = ({ languageKey, theoryId }) => {
  const ui = useLessonTheoryTranslatesUi(languageKey);
  const dispatch = useDispatch();

  const {
    data: theoryDetails,
    isLoading: isTheoryDetailsLoading,
  } = useGetLessonTheoryDetailsQuery(theoryId, { skip: !theoryId });

  const [
    addVideo,
    {
      isLoading: isUploadingVidep,
    }
  ] = useAddVideoToTheoryMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {},
    values: {
      title: theoryDetails?.translates?.[languageKey]?.video?.title,
    }
  });

  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('file', values.file);

    try {
      await addVideo({ theoryId, languageKey, data: formData }).unwrap();
      dispatch(closeLessonTheoryVideoForm(languageKey))
    } catch (e) {
      console.log(e);
    }
  };

  const render = () => {
    if (isTheoryDetailsLoading) return <Spin spinning />

    const currentData = theoryDetails.translates[languageKey]?.video;

    if (ui.isVideoEditing) return (
      <form onSubmit={submit(handleSubmit)}>
        <InputController control={control} label="Название видео" name="title" />
        <VideoPickerController control={control} name="file" />
        <Button htmlType="submit" block type="primary" loading={isUploadingVidep} style={{ marginTop: 20 }}>
          Сохранить
        </Button>
      </form>
    )
    if (!currentData) return <Empty description="Видео еще не загружено" />

    return (
      <div>
        <Typography.Text>{currentData.title}</Typography.Text>
        <ApiVideoPlayer
          video={{
            id: currentData.id,
            token: parseQuery(currentData.videoUrl)?.token,
          }}
          style={{ height: '200px' }}
        />
      </div>
    )
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4} style={{ marginTop: 0 }}>Видео урока</Typography.Title>
        </Col>
        <Col>
          <Button icon={<FiEdit2/>} type="text" onClick={() => dispatch(toggleLessonTheoryVideoForm(languageKey))} />
        </Col>
      </Row>
      {render()}
    </div>
  );
};

export default LessonVideos;
