import React from 'react';
import { useController } from 'react-hook-form';
import { InputNumber, Typography } from 'antd';

const InputNumberController = ({
  control,
  name,
  label,
  ...restProps
}) => {
  const { field, fieldState } = useController({
    name, control,
  });

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: 20,
      }}
    >
      <div>
        <Typography.Text type={fieldState.error ? 'danger' : 'secondary'}>
          {label}
        </Typography.Text>
      </div>
      <InputNumber
        value={field.value}
        onChange={field.onChange}
        status={fieldState.error ? 'error' : null}
        {...restProps}
      />
      <div
        style={{
          textAlign: 'right',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Typography.Text type="danger">
          {fieldState.error ? fieldState.error.message : null}
        </Typography.Text>
      </div>
    </div>
  )
};

export default InputNumberController;
