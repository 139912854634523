import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTopics: build.query({
      query: (params) => ({
        url: routes.topics(),
        params,
      }),
      providesTags: ['TOPICS'],
    }),
    closeTopic: build.mutation({
      query: (id) => ({
        url: routes.closeTopic(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TOPICS'] }),
    }),
    openTopic: build.mutation({
      query: (id) => ({
        url: routes.openTopic(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TOPICS'] }),
    }),
    hideTopic: build.mutation({
      query: (id) => ({
        url: routes.visibilityTopic(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TOPICS'] }),
    }),
    unhideTopic: build.mutation({
      query: (id) => ({
        url: routes.visibilityTopic(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TOPICS'] }),
    }),
    processTopic: build.mutation({
      query: (id) => ({
        url: routes.processTopic(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TOPICS'] }),
    }),

    getComments: build.query({
      query: (params) => ({
        url: routes.comments(),
        params,
      }),
      providesTags: ['COMMENTS'],
    }),
    markAsSolution: build.mutation({
      query: (id) => ({
        url: routes.markCommentSolution(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['COMMENTS'] }),
    }),
    unmarkAsSolution: build.mutation({
      query: (id) => ({
        url: routes.markCommentSolution(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['COMMENTS'] }),
    }),
    hideComment: build.mutation({
      query: (id) => ({
        url: routes.visibilityComment(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['COMMENTS'] }),
    }),
    unhideComment: build.mutation({
      query: (id) => ({
        url: routes.visibilityComment(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['COMMENTS'] }),
    }),
  }),
});

export const {
  useGetSuspensionReasonsQuery,
  useGetTopicsQuery,
  useCloseTopicMutation,
  useOpenTopicMutation,
  useHideTopicMutation,
  useUnhideTopicMutation,
  useProcessTopicMutation,
  useGetCommentsQuery,
  useMarkAsSolutionMutation,
  useUnmarkAsSolutionMutation,
  useHideCommentMutation,
  useUnhideCommentMutation,
} = mainApi;
