
import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getLessonTheories: build.query({
      query: (params) => ({
        url: routes.lessonTheory(),
        params,
      }),
      providesTags: ['LESSON_THEORIES'],
    }),
    getLessonTheoryDetails: build.query({
      query: (theoryId) => ({
        url: routes.lessonTheory(theoryId),
      }),
      providesTags: ['LESSON_THEORY_DETAILS'],
    }),
    createLessonTheory: build.mutation({
      query: (data) => ({
        url: routes.lessonTheory(),
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_THEORIES', 'LESSON_THEORY_DETAILS'] })
    }),
    editLessonTheory: build.mutation({
      query: ({ theoryId, data }) => ({
        url: routes.lessonTheory(theoryId),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_THEORIES', 'LESSON_THEORY_DETAILS'] })
    }),
    publishLessonTheory: build.mutation({
      query: (theoryId) => ({
        url: routes.lessonTheoryPublication(theoryId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_THEORIES', 'LESSON_THEORY_DETAILS'] })
    }),
    unpublishLessonTheory: build.mutation({
      query: (theoryId) => ({
        url: routes.lessonTheoryPublication(theoryId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_THEORIES', 'LESSON_THEORY_DETAILS'] })
    }),
    appendTranslatesToLessonTheory: build.mutation({
      query: ({ theoryId, languageKey, data }) => ({
        url: routes.lessonTheoryTranslates(theoryId, languageKey),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_THEORIES', 'LESSON_THEORY_DETAILS'] })
    }),

    addVideoToTheory: build.mutation({
      query: ({ theoryId, languageKey, data }) => ({
        url: routes.lessonTheoryVideos(theoryId, languageKey),
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_THEORY_DETAILS'] })
    }),
    removeVideoToTheory: build.mutation({
      query: ({ theoryId, languageKey }) => ({
        url: routes.lessonTheoryVideos(theoryId, languageKey),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_THEORY_DETAILS'] })
    }),
  }),
});

export const {
  useGetLessonTheoriesQuery,
  useCreateLessonTheoryMutation,
  useEditLessonTheoryMutation,
  useGetLessonTheoryDetailsQuery,
  useAppendTranslatesToLessonTheoryMutation,
  usePublishLessonTheoryMutation,
  useUnpublishLessonTheoryMutation,
  useAddVideoToTheoryMutation,
  useRemoveVideoToTheoryMutation,
} = mainApi;
