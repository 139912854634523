import { mainApi } from '../index';
import routes from '../routes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getSuspensionReasons: build.query({
      query: (params) => ({
        url: routes.suspensionReasons(),
        params,
      }),
      providesTags: ['SUSPENSION_REASONS'],
    }),
  }),
});

export const {
  useGetSuspensionReasonsQuery,
} = mainApi;
