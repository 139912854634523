import React from 'react';
import cn from 'classnames';

import styles from './ChangeUserPassword.module.scss';
import { Button, Drawer } from 'antd';
import useToggle from '../../../hooks/useToggle';
import { useForm } from 'react-hook-form';
import { useChangeUserPasswordMutation } from '../../../store/api/endpoints/users';
import InputController from '../../shared/controllers/InputController';
import { toast } from 'react-toastify';

const ChangeUserPassword = ({ userId }) => {
  const [open, openDrawer, closeDrawer] = useToggle();

  const [changePassword, { isLoading: isChanging }] = useChangeUserPasswordMutation();

  const {
    control,
    handleSubmit: submit,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const handleSubmit = async (values) => {
    try {
      await changePassword({ userId, data: values }).unwrap();
      closeDrawer();
      toast.success('Пароль сменен');
      reset();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer} block>Сменить пароль</Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Сменить пароль"
        destroyOnClose
        maskClosable={false}
      >
        <form onSubmit={submit(handleSubmit)}>
          <InputController control={control} name="password" label="Пароль" />
          <Button loading={isChanging} htmlType="submit" type="primary" block>Сохранить</Button>
        </form>
      </Drawer>
    </>
  );
};

export default ChangeUserPassword;
