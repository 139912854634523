import React, { useState } from 'react';
import cn from 'classnames';

import styles from './CreateRepoCredentialsFormDrawer.module.scss';
import { Button, Drawer } from 'antd';
import useToggle from '../../../hooks/useToggle';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import { useCreateRepoCredentialsMutation } from '../../../store/api/endpoints/repoCredentials';
import { toast } from 'react-toastify';

const CreateRepoCredentialsFormDrawer = () => {
  const [open, openDrawer, closeDrawer] = useToggle(false);

  const {
    control,
    handleSubmit: submit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const [
    createRepoCredentials,
    { isLoading: isCreating },
  ] = useCreateRepoCredentialsMutation();

  const handleSubmit = async (values) => {
    try {
      await createRepoCredentials(values).unwrap();
      closeDrawer();
      toast.success('Создано');
    } catch (e) {

    }
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer}>
        Создать
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать креденшелы"
        destroyOnClose
        maskClosable={false}
      >
        <form onSubmit={submit(handleSubmit)}>
          <InputController control={control} name="host" label="Host" />
          <InputController control={control} name="username" label="Username" />
          <InputController control={control} name="password" label="Пароль/Токен" />
          <Button htmlType="submit" type="primary" block loading={isCreating}>
            Сохранить
          </Button>
        </form>
      </Drawer>
    </>
  );
};

export default CreateRepoCredentialsFormDrawer;
