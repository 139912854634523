import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTariffs: build.query({
      query: (params) => ({
        url: routes.tariffs(),
        params,
      }),
      providesTags: ['TARIFFS'],
    }),
    getTariffDetails: build.query({
      query: (id) => ({
        url: routes.tariffs(id),
      }),
      providesTags: ['TARIFF_DETAILS'],
    }),
    createTariff: build.mutation({
      query: (data) => ({
        url: routes.tariffs(),
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFFS'] }),
    }),
    editTariff: build.mutation({
      query: ({ tariffId, data }) => ({
        url: routes.tariffs(tariffId),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFF_DETAILS'] }),
    }),
    removeTariff: build.mutation({
      query: (tariffId) => ({
        url: routes.tariffs(tariffId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFFS'] }),
    }),
    publishTariff: build.mutation({
      query: (tariffId) => ({
        url: routes.tariffPublication(tariffId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFF_DETAILS'] }),
    }),
    unpublishTariff: build.mutation({
      query: (tariffId) => ({
        url: routes.tariffPublication(tariffId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TARIFF_DETAILS'] }),
    }),
  }),
});

export const {
  useGetTariffsQuery,
  useGetTariffDetailsQuery,
  useCreateTariffMutation,
  useEditTariffMutation,
  useRemoveTariffMutation,
  usePublishTariffMutation,
  useUnpublishTariffMutation,
} = mainApi;
