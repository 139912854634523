import React from 'react';
import { Table } from 'antd';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import useQueryString from '../../hooks/useQueryString';
import usePaginationQueries from '../../hooks/usePaginationQueries';

const TableWrapper = ({
  data,
  columns,
  total,
  ...rest
}) => {
  const navigate = useNavigate();
  const query = useQueryString();
  const { page, pageSize } = usePaginationQueries();

  const handlePageChange = (page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ ...query, page: page.current, pageSize: page.pageSize }),
    });
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      onChange={handlePageChange}
      size="small"
      pagination={{
        total: total,
        current: page,
        locale: { items_per_page: `/ на странице` },
        pageSize,
        showSizeChanger: true,
        showTitle: true,
        pageSizeOptions: [20, 50, 100],
      }}
      {...rest}
    />
  )
};

export default TableWrapper;
