
import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getLessons: build.query({
      query: (params) => ({
        url: routes.lessons(),
        params
      }),
      providesTags: ['LESSONS'],
    }),
    getLessonDetails: build.query({
      query: (id) => ({
        url: routes.lessons(id),
      }),
      providesTags: ['LESSON_DETAILS'],
    }),
    createLesson: build.mutation({
      query: (data) => ({
        url: routes.lessons(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['LESSONS', 'LESSON_DETAILS'] })
    }),
    editLesson: build.mutation({
      query: ({ id, data }) => ({
        url: routes.lessons(id),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['LESSONS', 'LESSON_DETAILS'] })
    }),
    appendTranslateToLesson: build.mutation({
      query: ({ id, languageKey, data }) => ({
        url: routes.lessonTranslates(id, languageKey),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_DETAILS'] })
    }),
    publishLesson: build.mutation({
      query: (id) => ({
        url: routes.lessonPublication(id),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_DETAILS', 'LESSONS'] })
    }),
    unpublishLesson: build.mutation({
      query: (id) => ({
        url: routes.lessonPublication(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_DETAILS', 'LESSONS'] })
    }),
    deleteLesson: build.mutation({
      query: (id) => ({
        url: routes.lessons(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_DETAILS', 'LESSONS'] })
    }),
  }),
});

export const {
  useGetLessonsQuery,
  useGetLessonDetailsQuery,
  useCreateLessonMutation,
  useEditLessonMutation,
  useAppendTranslateToLessonMutation,
  usePublishLessonMutation,
  useUnpublishLessonMutation,
  useDeleteLessonMutation,
} = mainApi;
