import React from 'react';
import cn from 'classnames';

import styles from './MainTheoryForm.module.scss';
import CheckableTagsController from '../../shared/controllers/CheckableTagsController';
import { LANGUAGES } from '../../../constants';
import { useForm } from 'react-hook-form';
import { Button, Flex } from 'antd';

const MainTheoryForm = ({
  onSubmit,
  loading,
  initialValues,
}) => {
  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: initialValues,
  });

  return (
    <form onSubmit={submit(onSubmit)}>
      <CheckableTagsController
        name="availableLanguages"
        label="Доступен на языках"
        control={control}
        options={LANGUAGES}
      />
      <Button type="primary" htmlType="submit" loading={loading} block>
        Сохранить
      </Button>
    </form>
  );
};

export default MainTheoryForm;
