import React from 'react';
import cn from 'classnames';

import styles from './AnswersList.module.scss';
import { useGetTestAnswersQuery } from '../../../store/api/endpoints/lessonTests';
import { Empty, List, Spin, Typography } from 'antd';
import CreateAnswerFormDrawer from '../CreateAnswerFormDrawer';
import DeleteAnswer from '../DeleteAnswer';
import EditAnswerFormDrawer from '../EditAnswerFormDrawer';

const AnswersList = ({ question }) => {
  const {
    data: answerData,
    isFetching: isAnswerDataFetching,
  } = useGetTestAnswersQuery({ question: question?.id }, { skip: !question });

  if (!question) return <Empty description="Кликните по вопросу чтобы посмотреть ответы"/>

  return (
    <div>
      <CreateAnswerFormDrawer questionId={question.id} />
      <Spin spinning={isAnswerDataFetching}>
        <List
          dataSource={answerData?.results}
          bordered
          style={{ marginTop: 12 }}
          renderItem={(item) => (
            <List.Item
              actions={[
                <EditAnswerFormDrawer answerId={item.id} />,
                <DeleteAnswer answerId={item.id} />,
              ]}
            >
              {item.isCorrect ? '✅' : '❌'}
              <Typography.Text style={{ marginLeft: 10 }} strong>{item.sysName}</Typography.Text>
            </List.Item>
          )}
        />
      </Spin>
    </div>
  );
};

export default AnswersList;
