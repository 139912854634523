import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  mainLessonTheoryUi: {
    isEditing: false,
  },
  lessonTheoryTranslates: {
    ru: {
      isEditing: false,
      isVideoEditing: false,
    },
    uz: {
      isEditing: false,
      isVideoEditing: false,
    },
  }
};

const reducerPath = 'lessonTheory';

const lessonTheorySlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    openMainLessonTheoryForm(state) {
      state.mainLessonTheoryUi.isEditing = true;
    },
    closeMainLessonTheoryForm(state) {
      state.mainLessonTheoryUi.isEditing = false;
    },
    toggleMainLessonTheoryForm(state) {
      state.mainLessonTheoryUi.isEditing = !state.mainLessonTheoryUi.isEditing;
    },

    openLessonTheoryTranslatesForm(state, { payload: langKey }) {
      state.lessonTheoryTranslates[langKey].isEditing = true;
    },
    closeLessonTheoryTranslatesForm(state, { payload: langKey }) {
      state.lessonTheoryTranslates[langKey].isEditing = false;
    },
    toggleLessonTheoryTranslatesForm(state, { payload: langKey }) {
      state.lessonTheoryTranslates[langKey].isEditing = !state.lessonTheoryTranslates[langKey].isEditing;
    },

    openLessonTheoryVideoForm(state, { payload: langKey }) {
      state.lessonTheoryTranslates[langKey].isVideoEditing = true;
    },
    closeLessonTheoryVideoForm(state, { payload: langKey }) {
      state.lessonTheoryTranslates[langKey].isVideoEditing = false;
    },
    toggleLessonTheoryVideoForm(state, { payload: langKey }) {
      state.lessonTheoryTranslates[langKey].isVideoEditing = !state.lessonTheoryTranslates[langKey].isVideoEditing;
    },
  },
});

export const {
  openMainLessonTheoryForm,
  closeMainLessonTheoryForm,
  toggleMainLessonTheoryForm,
  openLessonTheoryTranslatesForm,
  closeLessonTheoryTranslatesForm,
  toggleLessonTheoryTranslatesForm,
  openLessonTheoryVideoForm,
  closeLessonTheoryVideoForm,
  toggleLessonTheoryVideoForm,
} = lessonTheorySlice.actions;

export const useMainLessonTheoryUi = () => {
  return useSelector((state) => state[reducerPath].mainLessonTheoryUi);
};

export const useLessonTheoryTranslatesUi = (langKey) => {
  return useSelector((state) => state[reducerPath].lessonTheoryTranslates[langKey]);
};

export default lessonTheorySlice;
