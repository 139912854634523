import React, { useState } from 'react';
import cn from 'classnames';

import styles from './UsersPermissions.module.scss';
import { Button, Empty, Input, Space, Tag, Typography } from 'antd';
import { useAddPermissionMutation, useRemovePermissionMutation } from '../../../store/api/endpoints/users';

const UsersPermissions = ({ permissions, userId }) => {
  const [value, setValue] = useState('');

  const [
    addPermission,
    { isLoading: isAdding },
  ] = useAddPermissionMutation();

  const [
    removePermission,
    { isLoading: isRemoving },
  ] = useRemovePermissionMutation();

  const handleRemovePermission = (permission) => async () => {
    try {
      const data = {
        permissions: [permission],
      };
      await removePermission({ userId, data }).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  const handleAddPermission = async () => {
    try {
      const data = {
        permissions: [value],
      };
      await addPermission({ userId, data }).unwrap();
      setValue('');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div>
        <Typography.Title level={4} style={{ marginTop: 0 }}>Пермишены</Typography.Title>
      </div>
      {
        (!permissions || permissions.length === 0)
          ? (
            <Empty description="Нет установленных пермишенов" />
          ) : (
            <Space>
              {permissions?.map((permission) => (
                <Tag
                  color="green"
                  key={permission}
                  closeIcon
                  onClose={handleRemovePermission(permission)}
                >
                  {permission}
                </Tag>
              ))}
            </Space>
          )
      }
      <Space.Compact style={{ width: '100%', marginTop: 20 }}>
        <Input value={value} onChange={(e) => setValue(e.target.value)} />
        <Button type="primary" loading={isAdding} onClick={handleAddPermission}>Добавить</Button>
      </Space.Compact>
    </>
  );
};

export default UsersPermissions;
