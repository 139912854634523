import React from 'react';
import { useGetTagsQuery } from '../../../store/api/endpoints/tags';
import TableWrapper from '../../shared/TableWrapper';
import { useDispatch } from 'react-redux';
import { selectTag } from '../../../store/slicers/tagsSlice';

const TagList = () => {
  const dispatch = useDispatch();
  const {
    data: tagData,
    isFetching: isTagDataFetching,
  } = useGetTagsQuery();

  const columns = [
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Порядковый номер',
      key: 'placeInOrder',
      dataIndex: 'placeInOrder',
    },
    {
      title: 'Название',
      key: 'name',
      dataIndex: 'name',
      render: (data) => data?.ru,
    },
    {
      title: 'Продвигается?',
      key: 'isPromoted',
      dataIndex: 'isPromoted',
      render: (isPromoted) => isPromoted ? '✅' : '❌',
    },
  ];

  return (
    <TableWrapper
      data={tagData?.results}
      total={tagData?.count}
      columns={columns}
      loading={isTagDataFetching}
      onRow={(data) => ({
        onClick: () => {
          dispatch(selectTag(data));
        }
      })}
    />
  );
};

export default TagList;
