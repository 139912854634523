import React, { useState } from 'react';
import cn from 'classnames';

import styles from './EditPracticeDrawer.module.scss';
import { Button, Drawer } from 'antd';
import { FiEdit2 } from 'react-icons/fi';
import useToggle from '../../../hooks/useToggle';
import PracticeMainForm from '../PracticeMainForm';
import {
  useEditLessonPracticeMutation,
  useGetLessonPracticeDetailsQuery
} from '../../../store/api/endpoints/lessonPractice';

const EditPracticeDrawer = ({ practiceId }) => {
  const [open, openDrawer, closeDrawer] = useToggle(false);

  const {
    data: practice,
  } = useGetLessonPracticeDetailsQuery(practiceId);

  const [
    editPractice,
    {
      isLoading: isEditing,
    }
  ] = useEditLessonPracticeMutation();

  const handleSubmit = async (values) => {
    try {
      await editPractice({ id: practiceId, data: values }).unwrap();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button icon={<FiEdit2 />} type="text" onClick={openDrawer} />
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Редактировать практику"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <PracticeMainForm
          initialValues={practice}
          onSubmit={handleSubmit}
          loading={isEditing}
        />
      </Drawer>
    </>
  );
};

export default EditPracticeDrawer;
