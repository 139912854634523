import React from 'react';
import { useGetUsersQuery } from '../../../store/api/endpoints/users';
import TableWrapper from '../../shared/TableWrapper';
import { Spin, Typography } from 'antd';
import useQueryString from '../../../hooks/useQueryString';
import { truncate } from '../../../utils';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';

const UsersList = () => {
  const { page = 1, pageSize = 50, ...restQuery } = useQueryString();

  const {
    data: users,
    isLoading,
    isFetching,
  } = useGetUsersQuery({ page, pageSize, ...restQuery });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Typography.Text code copyable ellipsis>{truncate(id, 9)}</Typography.Text>
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (username, raw) => <Link to={routes.users(raw.id)}>{username}</Link>
    },
    {
      title: 'Фамилия',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Имя',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Отчество',
      dataIndex: 'secondName',
      key: 'secondName',
    },
    {
      title: 'Эл. почта',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Установлен пароль?',
      dataIndex: 'hasPassword',
      key: 'hasPassword',
      render: (hasPassword) => <Typography.Text type={hasPassword ? 'success' : 'danger'}>{hasPassword ? 'Да' : 'Нет'}</Typography.Text>
    },
    {
      title: 'Блокирован?',
      dataIndex: 'isSuspended',
      key: 'isSuspended',
      render: (isSuspended) => <Typography.Text type={isSuspended ? 'success' : 'danger'}>{isSuspended ? 'Да' : 'Нет'}</Typography.Text>
    },
  ];

  if (isLoading) return <Spin spinning />

  return (
    <TableWrapper
      data={users?.results}
      columns={columns}
      total={users?.count}
      loading={isFetching}
      rowKey="id"
    />
  );
};

export default UsersList;
