import React from 'react';
import cn from 'classnames';

import styles from './RepoCredentials.module.scss';
import RepoCredentialList from '../../../containers/repoCredentials/RepoCredentialList';
import { Flex, Typography } from 'antd';
import CreateRepoCredentialsFormDrawer from '../../../containers/repoCredentials/CreateRepoCredentialsFormDrawer';

const RepoCredentials = () => {

  return (
    <div>
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ marginTop: 0 }}>Креденшелы</Typography.Title>
        <CreateRepoCredentialsFormDrawer />
      </Flex>
      <RepoCredentialList />
    </div>
  );
};

export default RepoCredentials;
