import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import ModuleMainForm from '../ModuleMainForm';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { useCreateModuleMutation } from '../../../store/api/endpoints/modules';
import { toast } from 'react-toastify';

const ModuleCreationFormDrawer = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [
    createModule,
    { isLoading: isCreating },
  ] = useCreateModuleMutation();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleCreate = async (values) => {
    try {
      const module = await createModule(values).unwrap();
      closeDrawer();
      navigate(routes.modules(module.id));
      toast.success('Успешно!');
    } catch (e) {
    }
  }

  return (
    <>
      <Button
        type="primary"
        htmlType="button"
        onClick={openDrawer}
      >
        Создать
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать модуль"
        destroyOnClose
        maskClosable={false}
      >
        <ModuleMainForm onSubmit={handleCreate} loading={isCreating} />
      </Drawer>
    </>
  );
};

export default ModuleCreationFormDrawer;
