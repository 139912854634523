import React from 'react';
import cn from 'classnames';

import styles from './PracticeMainForm.module.scss';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import { useGetTrainerTemplatesQuery } from '../../../store/api/endpoints/lessonPractice';
import SelectController from '../../shared/controllers/SelectController';
import { map } from 'ramda';
import CheckableTagsController from '../../shared/controllers/CheckableTagsController';
import { LANGUAGES } from '../../../constants';
import { Button } from 'antd';

const PracticeMainForm = ({
  onSubmit,
  loading,
  initialValues,
}) => {
  const {
    data: trainerTemplates,
    isFetching: isTrainerTemplatesFetching,
  } = useGetTrainerTemplatesQuery();

  const {
    control,
    handleSubmit: submit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: initialValues,
  });

  const handleSubmit = async (values) => {
    onSubmit(values);
  };

  return (
    <form onSubmit={submit(handleSubmit)}>
      <CheckableTagsController control={control} name="availableLanguages" label="Доступен на языках" options={LANGUAGES} />
      <InputController control={control} name="sysName" label="Системное название" disabled={loading} />
      <InputController control={control} name="gitRepositoryUrl" label="Ссылка на репозиторий" disabled={loading} />
      <SelectController
        control={control}
        name="trainerTemplate"
        label="Шаблон тренажера"
        options={trainerTemplates?.results}
        loading={isTrainerTemplatesFetching}
        disabled={loading}
      />
      <InputController control={control} name="testCommand" label="Команда для запуска тестов" disabled={loading} />
      <InputController control={control} name="testCommandWorkdir" label="Директория с тестами" disabled={loading} />
      <Button block htmlType="submit" type="primary" loading={loading}>
        Сохранить
      </Button>
    </form>
  );
};

export default PracticeMainForm;
