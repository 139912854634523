import React from 'react';
import cn from 'classnames';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { Button, Typography, Layout as AntLayout, Menu } from 'antd';
import styles from './Layout.module.scss';
import routes, { navs } from '../../../router/routes';
import store from 'store';

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const exit = () => {
    store.remove('credentials');
    navigate(routes.root());
  };

  return (
    <AntLayout hasSider>
      <AntLayout.Sider
        theme="light"
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Typography.Title level={4} style={{ marginTop: 22, textAlign: 'center' }}>
          Админка
        </Typography.Title>
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          style={{ borderInlineEnd: 'none' }}
          items={navs}
          onClick={({ key }) => {
            navigate(key);
          }}
        />
      </AntLayout.Sider>
      <AntLayout
        style={{
          marginLeft: 200,
        }}
      >
        <AntLayout.Header
          style={{
            padding: 0,
            background: '#fff',
          }}
        >
          <div className={styles.topbar}>
            <div className={styles.topbarLeft}></div>
            <div className={styles.topbarRight}>
              <Button danger type="text" onClick={exit}>
                Выход
              </Button>
            </div>
          </div>
        </AntLayout.Header>
        <AntLayout.Content
          style={{
            margin: '12px 12px 0',
            overflow: 'initial',
          }}
        >
          <Outlet />
        </AntLayout.Content>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
