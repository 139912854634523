import React from 'react';
import cn from 'classnames';

import styles from './ModuleTranslates.module.scss';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import UploadController from '../../shared/controllers/UploadController';
import { Button, Col, Empty, Row, Space, Spin, Typography } from 'antd';
import { toast } from 'react-toastify';
import { useAppendTranslateToModuleMutation, useGetModuleDetailsQuery } from '../../../store/api/endpoints/modules';
import {
  closeModuleTranslatesForm,
  toggleModuleMainDataForm,
  toggleModuleTranslatesForm,
  useTranslatesUi
} from '../../../store/slicers/moduleSlice';
import { FiEdit2, FiX } from 'react-icons/fi';
import { useDispatch } from 'react-redux';

const ModuleTranslates = ({
  languageKey,
  moduleId,
  title,
}) => {
  const translatesUi = useTranslatesUi(languageKey);
  const dispatch = useDispatch();

  const {
    data: module,
    isFetching,
    isLoading,
  } = useGetModuleDetailsQuery(moduleId, { skip: !moduleId });

  const [
    appendTranslate,
    { isLoading: isAppending },
  ] = useAppendTranslateToModuleMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    values: module?.translates?.[languageKey] ?? {
      name: null,
      shortDescription: null,
      description: null,
      image: null,
    },
  });

  const handleSubmit = async (values) => {
    if (!languageKey) {
      toast.warn('Отсутствует languageKey для этой формы');
      return;
    }
    try {
      await appendTranslate({ moduleId, languageKey, data: values }).unwrap();
      dispatch(closeModuleTranslatesForm(languageKey));
    } catch (e) {
      console.log(e);
    }
  };

  const render = () => {
    if (!moduleId) return <Empty description="Сначала нужно создать модуль" />;
    if (isLoading) return <Spin spinning />;
    if (!module) return <div>Запрос по модулю = {moduleId} ничего не вернул</div>

    const currentData = module.translates[languageKey];

    if (translatesUi.isEditing) return (
      <form onSubmit={submit(handleSubmit)}>
        <InputController control={control} name="name" label="Название" disabled={isAppending} />
        <InputController control={control} name="shortDescription" label="Коротное описание" type="textarea" disabled={isAppending} />
        <InputController control={control} name="description" label="Полное описание" type="textarea" disabled={isAppending} />
        <UploadController
          control={control}
          name="image"
          label="Картинка модуля"
          disabled={isAppending}
        />
        <Button htmlType="submit" block type="primary" style={{ marginTop: 10 }} loading={isAppending}>
          Сохранить
        </Button>
      </form>
    );

    if (!currentData) {
      return <Empty description="Поля еще не заполнены" />
    }

    return (
      <div>
        <Space direction="vertical">
          <div style={{ textAlign: 'center' }}>
            <img src={currentData.image.url} alt="Test" style={{ width: '50%' }} />
          </div>
          <div>
            <Typography.Text>Название</Typography.Text> <br/>
            <Typography.Text strong>{currentData.name || '-'}</Typography.Text>
          </div>
          <div>
            <Typography.Text>Короткое описание</Typography.Text> <br/>
            <Typography.Text strong>{currentData.shortDescription || '-'}</Typography.Text>
          </div>
          <div>
            <Typography.Text>Полное описание</Typography.Text> <br/>
            <Typography.Text strong>{currentData.description || '-'}</Typography.Text>
          </div>
        </Space>
      </div>
    )
  };


  return (
    <div>
      <Row justify="space-between" align="middle" wrap={false}>
        <Col>
          <Typography.Title level={4} style={{ marginTop: 0 }}>{title}</Typography.Title>
        </Col>
        <Col>
          <Button
            icon={translatesUi.isEditing ? <FiX /> : <FiEdit2 />}
            type="text"
            onClick={() => dispatch(toggleModuleTranslatesForm(languageKey))}
          />
        </Col>
      </Row>
      {render()}
    </div>
  );
};

export default ModuleTranslates;
