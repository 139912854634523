import React from 'react';
import cn from 'classnames';

import styles from './CommentsDrawer.module.scss';
import { clearSelectedTopic, useSelectedTopic } from '../../../store/slicers/topicsSlice';
import { Card, Drawer, Empty, Space, Spin, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useGetCommentsQuery } from '../../../store/api/endpoints/topics';
import MDEditor from '@uiw/react-md-editor';
import MarkCommentAsSolution from '../MarkCommentAsSolution';
import UnmarkCommentAsSolution from '../UnmarkCommentAsSolution';
import HideComment from '../HideComment';
import UnhideComment from '../UnhideComment';

const CommentsDrawer = () => {
  const dispatch = useDispatch();
  const selectedTopicId = useSelectedTopic();

  const {
    data: comments,
    isLoading: isCommentsLoading,
    isFetching: isCommentsFetching,
  } = useGetCommentsQuery({ topic: selectedTopicId, page: -1 }, { skip: !selectedTopicId });

  return (
    <Drawer
      open={selectedTopicId}
      onClose={() => dispatch(clearSelectedTopic())}
      placement="right"
      title="Комментарии к топику"
      destroyOnClose
      maskClosable={false}
      width="60%"
    >
      <Spin spinning={isCommentsLoading || isCommentsFetching}>
        {
          !comments
            ? <Empty description="Не удалось получить комменты по запросу" />
            : (
              <Space direction="vertical" style={{ width: '100%' }} size={20}>
                {comments.results.map(({ id, author, text, isSolution, isVisible }) => (
                  <Card
                    key={id}
                    size="small"
                    title={isSolution ? <Typography.Text color="green">Решение</Typography.Text> : null}
                    actions={[
                      isSolution ? <UnmarkCommentAsSolution id={id} /> : <MarkCommentAsSolution id={id} />,
                      isVisible ? <HideComment id={id} /> : <UnhideComment id={id} />
                    ]}
                  >
                    <Typography.Title level={5} style={{ marginTop: 0 }}>
                      {author.lastName} {author.firstName} - <Typography.Text code>{author.username}</Typography.Text>
                    </Typography.Title>
                    <div data-color-mode="light">
                      <MDEditor
                        theme="light"
                        value={text}
                        fullscreen={false}
                        overflow={false}
                        preview="preview"
                        hideToolbar
                      />
                    </div>
                  </Card>
                ))}
              </Space>
            )
        }
      </Spin>
    </Drawer>
  );
};

export default CommentsDrawer;
