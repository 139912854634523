import { format, intlFormat, isValid, parseISO } from 'date-fns';

export const formatDate = (date, withTime) => {
  if (!date) return null;
  const dateObj = date instanceof Date ? date : parseISO(date);
  if (!isValid(dateObj)) return null;

  return withTime
    ? intlFormat(dateObj, { day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' }, { locale: 'ru' })
    : intlFormat(dateObj, { day: '2-digit', month: 'long', year: 'numeric' }, { locale: 'ru' });
};
