import React from 'react';
import { useController } from 'react-hook-form';
import { DatePicker, Typography } from 'antd';

const DateInputController = ({
  control,
  name,
  label,
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
  });

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: 20,
      }}
    >
      <Typography.Text type={fieldState.error ? 'danger' : 'secondary'}>
        {label}
      </Typography.Text>
      <DatePicker
        value={field.value}
        onChange={field.onChange}
        style={{ width: '100%' }}
        status={fieldState.error ? 'error' : null}
        format="DD.MM.YYYY"
        {...props}
      />
      <div
        style={{
          textAlign: 'right',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Typography.Text type="danger">
          {fieldState.error ? fieldState.error.message : null}
        </Typography.Text>
      </div>
    </div>
  );
};

export default DateInputController;
