import React from 'react';
import { useEditModuleMutation, useGetModuleDetailsQuery } from '../../../store/api/endpoints/modules';
import {
  closeModuleMainDataForm,
  toggleModuleMainDataForm,
  useModuleMainDataUi
} from '../../../store/slicers/moduleSlice';
import { useDispatch } from 'react-redux';
import ModuleMainForm from '../ModuleMainForm';
import { Button, Col, Row, Space, Spin, Tag, Typography } from 'antd';
import { FiEdit2, FiX } from 'react-icons/fi';
import { filter, includes, map, pipe } from 'ramda';
import { LANGUAGES } from '../../../constants';
import PaidStatus from '../../shared/PaidStatus';
import AvailableLanguagesView from '../../shared/AvailableLanguagesView';

const ModuleMainData = ({ moduleId }) => {
  const ui = useModuleMainDataUi();
  const dispatch = useDispatch();

  const {
    data: module,
    isLoading,
  } = useGetModuleDetailsQuery(moduleId, { skip: !moduleId });

  const [
    editModule,
    { isLoading: isEditing },
  ] = useEditModuleMutation();

  const handleSubmit = async (values) => {
    try {
      await editModule({ id: moduleId, data: values }).unwrap();
      dispatch(closeModuleMainDataForm());
    } catch (e) {
      console.log(e);
    }
  };

  if (!moduleId) return <div>Нет ID модуля</div>;
  if (isLoading) return <Spin spinning />
  if (!module) return <div>Запрос по модулю = {moduleId} ничего не вернул</div>

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4} style={{ marginTop: 0 }}>Модуль</Typography.Title>
        </Col>
        <Col>
          <Button
            icon={ui.isEditing ? <FiX /> : <FiEdit2 />}
            type="text"
            onClick={() => dispatch(toggleModuleMainDataForm())}
          />
        </Col>
      </Row>
      {
        ui.isEditing
          ? (
            <ModuleMainForm
              initialValues={module}
              onSubmit={handleSubmit}
              loading={isEditing}
            />
          ) : (
            <div>
              <Row justify="space-between">
                <Col>
                  <AvailableLanguagesView languages={module.availableLanguages} />
                </Col>
                <Col>
                  <PaidStatus isFree={module.isFree} />
                </Col>
              </Row>
              <Space direction="vertical" style={{ marginTop: 20 }}>
                <div>
                  <Typography.Text>Системное название</Typography.Text> <br/>
                  <Typography.Text strong>{module.sysName}</Typography.Text>
                </div>
                <div>
                  <Typography.Text>Slug</Typography.Text> <br/>
                  <Typography.Text strong>{module.slug}</Typography.Text>
                </div>
              </Space>
            </div>
          )
      }
    </div>
  );
};

export default ModuleMainData;
