import React, { useState } from 'react';
import cn from 'classnames';

import styles from './CreateAnswerFormDrawer.module.scss';
import { Button, Drawer } from 'antd';
import { useNavigate } from 'react-router';
import AnswerForm from '../AnswerForm';

const CreateAnswerFormDrawer = ({ questionId }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer}>Создать ответ</Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать ответ"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <AnswerForm
          mode="CREATE"
          initialValues={{
            question: questionId,
            isCorrect: false,
          }}
          onSuccess={() => {
            closeDrawer();
          }}
        />
      </Drawer>
    </>
  );
};

export default CreateAnswerFormDrawer;
