import React, { useState } from 'react';
import cn from 'classnames';

import styles from './CreateQuestionFormDrawer.module.scss';
import { Button, Drawer } from 'antd';
import { useNavigate } from 'react-router';
import QuestionForm from '../QuestionForm';

const CreateQuestionFormDrawer = ({ lessonId }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer}>Создать вопрос</Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать вопрос"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <QuestionForm
          mode="CREATE"
          initialValues={{
            lesson: lessonId,
          }}
          onSuccess={closeDrawer}
        />
      </Drawer>
    </>
  );
};

export default CreateQuestionFormDrawer;
