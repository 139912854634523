import axios from 'axios';
import store from 'store';
import routes from './routes';

let isRefreshing = false;
let failedQueue = [];

const refreshToken = async (baseUrl) => {
  const credentials = store.get('credentials');
  return await axios.post(
    baseUrl + routes.refreshToken(),
    { refreshToken: credentials.refreshToken },
  );
};

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const baseQuery = ({ baseUrl }) => async (args) => {
  const credentials = store.get('credentials');

  const headers = {
    Authorization: credentials?.accessToken,
  };

  const axiosRequestConfig = {
    ...args,
    headers: {
      ...headers,
      ...args.headers,
    },
    url: baseUrl + args.url,
  };

  try {
    const response = await axios(axiosRequestConfig);
    return { data: response.data };
  } catch (e) {
    if (!e.response) {
      return {
        error: {
          state: 'APP_ERROR',
          data: e.message,
        }
      }
    }
    if (e.response.status === 401 && e.response.data?.message === 'Token expired') {
      const originalRequest = axiosRequestConfig;

      if (!isRefreshing) {
        isRefreshing = true;
        refreshToken(baseUrl)
          .then((response) => {
            store.set('credentials', response.data);
            processQueue(null, response.data.accessToken);
          })
          .catch(refreshError => {
            processQueue(refreshError, null);
          })
          .finally(() => {
            isRefreshing = false;
          });
      }

      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve: async (newToken) => {
            originalRequest.headers['Authorization'] = newToken;
            resolve(await axios(originalRequest));
          },
          reject: () => {
            reject(e);
          }
        });
      });
    }

    return {
      error: {
        state: e.response.status,
        data: e.response.data || e.message,
      }
    };
  }
};


export default baseQuery;
