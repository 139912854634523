import React from 'react';
import cn from 'classnames';

import styles from './CloseTopic.module.scss';
import { Button, Tooltip } from 'antd';
import { FiX } from 'react-icons/fi';
import { useCloseTopicMutation } from '../../../store/api/endpoints/topics';

const CloseTopic = ({ id }) => {
  const [
    closeTopic,
    { isLoading },
  ] = useCloseTopicMutation();

  const handleClick = async () => {
    try {
      await closeTopic(id).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Button
      type="text"
      danger
      onClick={handleClick}
      loading={isLoading}
    >
      Закрыть топик
    </Button>
  );
};

export default CloseTopic;
