import React from 'react';
import cn from 'classnames';

import styles from './UnhideComment.module.scss';
import { useUnhideCommentMutation, useUnhideTopicMutation } from '../../../store/api/endpoints/topics';
import { Button } from 'antd';

const UnhideComment = ({ id }) => {
  const [
    unhideComment,
    { isLoading },
  ] = useUnhideCommentMutation();

  const handleClick = async () => {
    try {
      await unhideComment(id).unwrap();
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <Button
      type="text"
      onClick={handleClick}
      loading={isLoading}
    >
      Опубликовать
    </Button>
  );
};

export default UnhideComment;
