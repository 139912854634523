import React from 'react';
import { useProcessTopicMutation } from '../../../store/api/endpoints/topics';
import { Button } from 'antd';

const ProcessTopic = ({ id }) => {
  const [
    processTopic,
    { isLoading },
  ] = useProcessTopicMutation();

  const handleClick = async () => {
    try {
      await processTopic(id).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Button
      type="text"
      onClick={handleClick}
      loading={isLoading}
    >
      Обработать
    </Button>
  );
};

export default ProcessTopic;
