import React from 'react';
import { Button, Flex, Typography } from 'antd';
import {
  useGetLessonPracticeDetailsQuery,
  usePublishLessonPracticeMutation,
  useUnpublishLessonPracticeMutation
} from '../../../store/api/endpoints/lessonPractice';

const PracticePublicationStatus = ({ practiceId }) => {
  const {
    data: practice,
  } = useGetLessonPracticeDetailsQuery(practiceId);

  const [
    publishPractice,
    { isLoading: isPublishing },
  ] = usePublishLessonPracticeMutation();
  const [
    unpublishPractice,
    { isLoading: isUnpublishing },
  ] = useUnpublishLessonPracticeMutation();

  const handleClickUnpublish = async () => {
    await unpublishPractice(practiceId)
  };
  const handleClickPublish = async () => {
    await publishPractice(practiceId);
  };

  const style = { marginTop: 0 };

  return practice?.isPublished
    ? (
      <Flex gap={10}>
        <Typography.Title type="success" level={3} style={style}>Опубликован</Typography.Title>
        <Button type="primary" danger onClick={handleClickUnpublish} loading={isUnpublishing}>
          Снять публикацию
        </Button>
      </Flex>
    ) : (
      <Flex gap={10}>
        <Typography.Title type="danger" level={4} style={style}>Не опубликован</Typography.Title>
        <Button type="primary" onClick={handleClickPublish} loading={isPublishing}>
          Опубликовать
        </Button>
      </Flex>
    )
};

export default PracticePublicationStatus;
