import React from 'react';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import CheckableTagsController from '../../shared/controllers/CheckableTagsController';
import { LANGUAGES } from '../../../constants';
import { Button, Flex } from 'antd';
import SwitchController from '../../shared/controllers/SwitchController';

const ModuleMainForm = ({ onSubmit, loading, initialValues }) => {
  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: initialValues,
    values: initialValues,
  });

  return (
    <form onSubmit={submit(onSubmit)}>
      <Flex vertical={false} gap={20} justify="space-between">
        <CheckableTagsController
          control={control}
          name="availableLanguages"
          label="Доступен на языках"
          options={LANGUAGES}
        />
        <SwitchController
          control={control}
          name="isFree"
          label="Бесплатный"
          checkedChildren="Бесплатный"
          unCheckedChildren="Платный"
          disabled={loading}
        />
      </Flex>
      <InputController control={control} name="sysName" label="Системное название" disabled={loading} />
      <InputController control={control} name="slug" label="Slug" disabled={loading} />
      <Flex justify="end">
        <Button type="primary" htmlType="submit" loading={loading} block>
          Сохранить
        </Button>
      </Flex>
    </form>
  );
};

export default ModuleMainForm;
