import { mainApi } from '../index';
import routes from '../routes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    login: build.mutation({
      query: (data) => ({
        url: routes.login(),
        method: 'POST',
        data,
      }),
    }),
    checkPermissions: build.query({
      query: (headers) => ({
        url: routes.me(),
        headers,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyCheckPermissionsQuery,
} = mainApi;
