import React from 'react';
import { useController } from 'react-hook-form';
import { Typography } from 'antd';
import MDEditor, { commands } from '@uiw/react-md-editor';

const MarkdownInputController = ({
  control,
  name,
  label,
  ...props
}) => {
  const { field, fieldState } = useController({
    control,
    name,
  });

  return (
    <div
      style={{
        position: 'relative',
        paddingBottom: 20,
      }}
    >
      <Typography.Text type={fieldState.error ? 'danger' : 'secondary'}>
        {label}
      </Typography.Text>

      <MDEditor
        theme="light"
        value={field.value}
        fullscreen={false}
        overflow={false}
        onChange={(value, event, state) => {
          field.onChange(value);
        }}
        {...props}
      />

      <div
        style={{
          textAlign: 'right',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Typography.Text type="danger">
          {fieldState.error ? fieldState.error.message : null}
        </Typography.Text>
      </div>
    </div>
  );
};

export default MarkdownInputController;
