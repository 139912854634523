import React from 'react';
import cn from 'classnames';

import styles from './LessonMainData.module.scss';
import { useEditLessonMutation, useGetLessonDetailsQuery } from '../../../store/api/endpoints/lessons';
import LessonMainForm from '../LessonMainForm';
import { Button, Col, Row, Space, Spin, Tag, Typography } from 'antd';
import {
  closeMainLessonDataForm,
  toggleMainLessonDataForm,
  useMainLessonDataUi
} from '../../../store/slicers/lessonsSlice';
import { FiEdit2, FiX } from 'react-icons/fi';
import { toggleModuleMainDataForm } from '../../../store/slicers/moduleSlice';
import { useDispatch } from 'react-redux';
import { filter, includes, map, pipe } from 'ramda';
import { LANGUAGES } from '../../../constants';
import PaidStatus from '../../shared/PaidStatus';
import AvailableLanguagesView from '../../shared/AvailableLanguagesView';

const LessonMainData = ({ lessonId }) => {
  const ui = useMainLessonDataUi();
  const dispatch = useDispatch();

  const {
    data: lesson,
    isLoading: isLessonLoading,
  } = useGetLessonDetailsQuery(lessonId, { skip: !lessonId });

  const [
    editLesson,
    { isLoading: isEditing },
  ] = useEditLessonMutation();

  const handleSubmit = async (values) => {
    try {
      await editLesson({
        id: lessonId,
        data: values,
      }).unwrap();
      dispatch(closeMainLessonDataForm());
    } catch (e) {

    }
  }

  if (isLessonLoading) return <Spin spinning />
  if (!lesson) return <div>Запрос на урок {lessonId} ничего не вернул</div>

  return (
    <div>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography.Title level={4} style={{ marginTop: 0 }}>Урок</Typography.Title>
        </Col>
        <Col>
          <Button
            icon={ui.isEditing ? <FiX /> : <FiEdit2 />}
            type="text"
            onClick={() => dispatch(toggleMainLessonDataForm())}
          />
        </Col>
      </Row>
      <div style={{ marginTop: 20 }}>
        {
          ui.isEditing
            ? (
              <LessonMainForm
                onSubmit={handleSubmit}
                loading={isEditing}
                initialValues={lesson}
              />
            ) : (
              <div>
                <Row justify="space-between">
                  <Col>
                    <Typography.Text>Порядковый номер</Typography.Text> <br/>
                    <Typography.Text strong>{lesson.placeInOrder}</Typography.Text>
                  </Col>
                  <Col>
                    <AvailableLanguagesView languages={lesson.availableLanguages} />
                  </Col>
                  <Col>
                    <PaidStatus isFree={lesson.isFree} />
                  </Col>
                </Row>
                <Space direction="vertical" style={{ marginTop: 20 }}>
                  <div>
                    <Typography.Text>Системное название</Typography.Text> <br/>
                    <Typography.Text strong>{lesson.sysName}</Typography.Text>
                  </div>
                  <div>
                    <Typography.Text>Slug</Typography.Text> <br/>
                    <Typography.Text strong>{lesson.slug}</Typography.Text>
                  </div>
                </Space>
              </div>
            )
        }
      </div>
    </div>
  );
};

export default LessonMainData;
