import React from 'react';
import { useUnmarkAsSolutionMutation } from '../../../store/api/endpoints/topics';
import { Button } from 'antd';

const UnmarkCommentAsSolution = ({ id }) => {
  const [
    unmarkAsSolution,
    { isLoading },
  ] = useUnmarkAsSolutionMutation();

  const handleClick = async () => {
    try {
      await unmarkAsSolution(id).unwrap();
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <Button
      type="text"
      onClick={handleClick}
      loading={isLoading}
    >
      Убрать метку "решение"
    </Button>
  );
};

export default UnmarkCommentAsSolution;
