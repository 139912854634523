import { isRejectedWithValue } from '@reduxjs/toolkit';
import { Modal } from 'antd';
import { toast } from 'react-toastify';
import store from 'store';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (!isRejectedWithValue(action)) return next(action);

  const { data, state } = action.payload;

  if (state === 400) {
    if (data.type === 'form') {
      Modal.error({
        title: 'Исправьте ошибки',
        content: (
          <div>
            {data.details?.map(({ errors, name }) => (
              <div key={name}>
                <span style={{ fontWeight: 700 }}>{name}</span>
                {' - '}
                <span>{errors}</span>
              </div>
            ))}
          </div>
        )
      });
      return next(action);
    }
    toast.error(data.message, { toastId: data.message });
  }

  if (state === 401) {
    toast.error(data.message, { toastId: data.message });
  }

  if (state === 403) {
    store.remove('credentials');
    toast.error(data.message, { toastId: data.message });
  }

  if (state === 404) {
    toast.error(data.message, { toastId: data.message });
  }

  if (state >= 500) {
    toast.error(`${state}: Ошибка сервера`, { toastId: 500 });
  }

  return next(action);
}
