import React, { useState } from 'react';
import cn from 'classnames';

import styles from './CreateLessonTheoryDrawer.module.scss';
import { Button, Drawer } from 'antd';
import MainTheoryForm from '../MainTheoryForm';
import { useCreateLessonTheoryMutation } from '../../../store/api/endpoints/lessonTheory';

const CreateLessonTheoryDrawer = ({ lessonId }) => {
  const [open, setOpen] = useState(false);
  const [
    createLessonTheory,
    { isLoading: isCreating },
  ] = useCreateLessonTheoryMutation();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      await createLessonTheory(values).unwrap();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer}>
        Создать Теорию
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        destroyOnClose
        width={600}
      >
        <MainTheoryForm
          initialValues={{ lesson: lessonId }}
          onSubmit={handleSubmit}
          loading={isCreating}
        />
      </Drawer>
    </>
  );
};

export default CreateLessonTheoryDrawer;
