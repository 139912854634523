

import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getLessonQuestions: build.query({
      query: (params) => ({
        url: routes.lessonQuestions(),
        params,
      }),
      providesTags: ['LESSON_QUESTIONS'],
    }),
    getLessonQuestionDetails: build.query({
      query: (id) => ({
        url: routes.lessonQuestions(id),
      }),
      providesTags: ['LESSON_QUESTION_DETAILS'],
    }),
    getQuestionTypes: build.query({
      query: (params) => ({
        url: routes.questionTypes(),
        params,
      }),
    }),
    createTestQuestion: build.mutation({
      query: (data) => ({
        url: routes.lessonQuestions(),
        data,
        method: 'POST',
      }),
    }),
    editTestQuestion: build.mutation({
      query: ({ questionId, data }) => ({
        url: routes.lessonQuestions(questionId),
        data,
        method: 'PUT',
      }),
    }),
    deleteTestQuestion: build.mutation({
      query: (questionId) => ({
        url: routes.lessonQuestions(questionId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_QUESTIONS', 'LESSON_QUESTION_DETAILS'] }),
    }),
    appendTranslatesToLessonQuestion: build.mutation({
      query: ({ questionId, languageKey, data  }) => ({
        url: routes.lessonQuestionTranslates(questionId, languageKey),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_QUESTIONS', 'LESSON_QUESTION_DETAILS'] }),
    }),
    publishLessonQuestion: build.mutation({
      query: (questionId) => ({
        url: routes.lessonQuestionPublication(questionId),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_QUESTIONS'] })
    }),
    unpublishLessonQuestion: build.mutation({
      query: (questionId) => ({
        url: routes.lessonQuestionPublication(questionId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_QUESTIONS'] })
    }),

    getTestAnswers: build.query({
      query: (params) => ({
        url: routes.lessonTestAnswers(),
        params,
      }),
      providesTags: ['TEST_ANSWERS'],
    }),
    getTestAnswerDetails: build.query({
      query: (id) => ({
        url: routes.lessonTestAnswers(id),
      }),
      providesTags: ['TEST_ANSWER_DETAILS'],
    }),
    createTestAnswer: build.mutation({
      query: (data) => ({
        url: routes.lessonTestAnswers(),
        data,
        method: 'POST',
      }),
    }),
    editAnswer: build.mutation({
      query: ({ answerId, data }) => ({
        url: routes.lessonTestAnswers(answerId),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TEST_ANSWERS', 'TEST_ANSWER_DETAILS'] }),
    }),
    deleteAnswer: build.mutation({
      query: (answerId) => ({
        url: routes.lessonTestAnswers(answerId),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['TEST_ANSWERS', 'TEST_ANSWER_DETAILS'] }),
    }),
    appendTranslatesToTestAnswer: build.mutation({
      query: ({ answerId, languageKey, data  }) => ({
        url: routes.lessonTestAnswerTranslates(answerId, languageKey),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['TEST_ANSWERS', 'TEST_ANSWER_DETAILS'] }),
    }),
  }),
});

export const {
  useGetLessonQuestionsQuery,
  useGetQuestionTypesQuery,
  useCreateTestQuestionMutation,
  useAppendTranslatesToLessonQuestionMutation,
  usePublishLessonQuestionMutation,
  useUnpublishLessonQuestionMutation,
  useLazyGetLessonQuestionDetailsQuery,
  useEditTestQuestionMutation,
  useDeleteTestQuestionMutation,
  useGetTestAnswersQuery,
  useCreateTestAnswerMutation,
  useAppendTranslatesToTestAnswerMutation,
  useDeleteAnswerMutation,
  useLazyGetTestAnswerDetailsQuery,
  useEditAnswerMutation,
} = mainApi;
