import { createBrowserRouter } from 'react-router-dom';
import LoginRoute from './components/Login';
import RequireAuth from './components/RequireAuth';
import Layout from '../containers/shared/Layout';
import ModulesRoute from './components/Modules';
import TagsRoute from './components/Tags';
import ModulePageRoute from './components/ModulePage';
import LessonPageRoute from './components/LessonPage';
import UsersRoute from './components/Users';
import UserPageRoute from './components/UserPage';
import TariffsRoute from './components/Tariffs';
import TariffPageRoute from './components/TariffPage';
import RepoCredentialsRoute from './components/RepoCredentials';
import TopicsRoute from './components/Topics';

const router = createBrowserRouter([
  LoginRoute,
  {
    path: '/app',
    element: <RequireAuth><Layout /></RequireAuth>,
    children: [
      ModulesRoute,
      ModulePageRoute,
      TagsRoute,
      LessonPageRoute,
      UsersRoute,
      UserPageRoute,
      TariffsRoute,
      TariffPageRoute,
      RepoCredentialsRoute,
      TopicsRoute,
    ],
  },
]);

export default router;
