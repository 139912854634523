import React from 'react';
import { Flex, Spin, Typography } from 'antd';
import ModuleMainForm from '../../../containers/modules/ModuleMainForm';
import { useGetModuleDetailsQuery } from '../../../store/api/endpoints/modules';
import { useParams } from 'react-router';
import ModuleTags from '../../../containers/modules/ModuleTags';
import ModuleTranslates from '../../../containers/modules/ModuleTranslates';
import ModulePublicationStatusForm from '../../../containers/modules/ModulePublicationStatusForm';
import LessonList from '../../../containers/lessons/LessonList';
import LessonCreationFormDrawer from '../../../containers/lessons/LessonCreationFormDrawer';
import ModuleMainData from '../../../containers/modules/ModuleMainData';

const ModulePage = () => {
  const { id } = useParams();

  const {
    data: module,
    isFetching,
    isLoading,
  } = useGetModuleDetailsQuery(id, { skip: !id });

  return (
    <Spin spinning={isLoading}>
      <Flex gap={12} justify="space-between" align="center">
        <Typography.Title style={{ marginTop: 0 }}>{module?.sysName}</Typography.Title>
        <ModulePublicationStatusForm moduleId={id} />
      </Flex>
      <Flex gap={12}>
        <div style={{ width: '30%' }} className="paper">
          <ModuleMainData moduleId={id} />
        </div>
        <div style={{ width: '30%' }} className="paper">
          <ModuleTranslates
            languageKey="ru"
            title="Переводимые поля на русском"
            moduleId={id}
          />
        </div>
        <div style={{ width: '30%' }} className="paper">
          <ModuleTranslates
            languageKey="uz"
            title="Переводимые поля на узбекском"
            moduleId={id}
          />
        </div>
        <div style={{ width: '10%' }} className="paper">
          <Typography.Title level={4} style={{ marginTop: 0 }}>Тэги</Typography.Title>
          <ModuleTags moduleId={id} />
        </div>
      </Flex>
      <Flex gap={12}>
        <div className="paper" style={{ width: '50%', marginTop: 12 }}>
          <Flex justify="space-between" align="center" style={{ marginBottom: 10 }}>
            <Typography.Title level={4} style={{ marginTop: 0 }}>Уроки</Typography.Title>
            <LessonCreationFormDrawer moduleId={id} />
          </Flex>
          <LessonList moduleId={id} />
        </div>
      </Flex>
    </Spin>
  );
};

export default ModulePage;
