import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  mainDataUi: {
    isEditing: false,
  },
  translatesUi: {
    ru: {
      isEditing: false,
    },
    uz: {
      isEditing: false,
    },
  },
};

const reducerPath = 'modules';

const moduleSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    openModuleMainDataForm(state) {
      state.mainDataUi.isEditing = true;
    },
    closeModuleMainDataForm(state) {
      state.mainDataUi.isEditing = false;
    },
    toggleModuleMainDataForm(state) {
      state.mainDataUi.isEditing = !state.mainDataUi.isEditing;
    },

    openModuleTranslatesForm(state, { payload: langKey }) {
      state.translatesUi[langKey].isEditing = true;
    },
    closeModuleTranslatesForm(state, { payload: langKey }) {
      state.translatesUi[langKey].isEditing = false;
    },
    toggleModuleTranslatesForm(state, { payload: langKey }) {
      state.translatesUi[langKey].isEditing = !state.translatesUi[langKey].isEditing;
    },

  },
});

export const {
  openModuleMainDataForm,
  closeModuleMainDataForm,
  toggleModuleMainDataForm,

  openModuleTranslatesForm,
  closeModuleTranslatesForm,
  toggleModuleTranslatesForm,
} = moduleSlice.actions;

export const useModuleMainDataUi = () => {
  return useSelector((state) => state[reducerPath].mainDataUi);
};

export const useTranslatesUi = (langKey) => {
  return useSelector((state) => state[reducerPath].translatesUi[langKey]);
};

export default moduleSlice;
