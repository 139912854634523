import React from 'react';
import cn from 'classnames';

import styles from './TariffList.module.scss';
import { useGetTariffsQuery } from '../../../store/api/endpoints/tariffs';
import useQueryString from '../../../hooks/useQueryString';
import TableWrapper from '../../shared/TableWrapper';
import { Empty, Spin, Typography } from 'antd';
import { formatNumber, truncate } from '../../../utils';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';

const TariffList = () => {
  const { page = 1, pageSize = 50, ...restQuery } = useQueryString();

  const {
    data: tariffs,
    isLoading: isTariffsLoading,
    isFetching: isTariffsFetching,
  } = useGetTariffsQuery({ page, pageSize, ...restQuery });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <Typography.Text code copyable ellipsis>{truncate(id, 9)}</Typography.Text>
    },
    {
      title: 'No.',
      dataIndex: 'placeInOrder',
      key: 'placeInOrder',
    },
    {
      title: 'Название (RU)',
      dataIndex: 'name',
      key: 'name',
      render: (name, raw) => <Link to={routes.tariffs(raw.id)}>{name.ru}</Link>
    },
    {
      title: 'Название (UZ)',
      dataIndex: 'name',
      key: 'name',
      render: (name) => name.uz,
    },
    {
      title: 'Изображение (RU)',
      dataIndex: 'picture',
      key: 'picture',
      render: (picture) => <img src={picture?.ru?.url} alt="" style={{ width: 80 }} />,
    },
    {
      title: 'Изображение (UZ)',
      dataIndex: 'picture',
      key: 'picture',
      render: (picture) => <img src={picture?.uz?.url} alt="" style={{ width: 80 }} />,
    },
    {
      title: 'Цена',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `${formatNumber(price)} сум`,
    },
    {
      title: 'Кол-во дней',
      dataIndex: 'daysCount',
      key: 'daysCount',
    },
    {
      title: 'Опубликован?',
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (isPublished) => isPublished
        ? <Typography.Text type="success">Да</Typography.Text>
        : <Typography.Text type="danger">Нет</Typography.Text>,
    },
  ];

  if (isTariffsLoading) return <Spin spinning />
  if (!tariffs) return <Empty description="Нет тарифов" />

  return (
    <TableWrapper
      total={tariffs.count}
      data={tariffs.results}
      columns={columns}
      loading={isTariffsFetching}
    />
  );
};

export default TariffList;
