import React from 'react';

import styles from './LoginForm.module.scss';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { Button, Typography } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLazyCheckPermissionsQuery, useLoginMutation } from '../../../store/api/endpoints/entry';
import store from 'store';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { hasAny } from '../../../utils';
import { toast } from 'react-toastify';
import InputController from '../../shared/controllers/InputController';

const formValidation = object({
  login: string().required(),
  password: string().required(),
})

const LoginForm = () => {
  const navigate = useNavigate();
  const [
    login,
    { isLoading },
  ] = useLoginMutation();
  const [
    getMyPermissions,
    {
      isFetching: isGetMyPermissionsFetching,
    }
  ] = useLazyCheckPermissionsQuery();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {
      login: '',
      password: '',
    },
    resolver: yupResolver(formValidation),
  });

  const handleSubmit = async (values) => {
    try {
      const credentials = await login(values).unwrap();
      const my = await getMyPermissions({ Authorization: credentials.accessToken }).unwrap();
      const hasPermissions = hasAny(my.permissions, ['moderator', 'content-admin', 'admin', 'god', 'service']);
      if (hasPermissions) {
        store.set('credentials', credentials);
        navigate(routes.app());
        return;
      }
      toast.error('You shall not pass');
    } catch (e) {

    }
  };

  return (
    <form onSubmit={submit(handleSubmit)} className={styles.form}>
      <Typography.Title level={3} style={{ marginTop: 0 }}>Войти</Typography.Title>
      <div className={styles.formItems}>
        <InputController
          control={control}
          name="login"
          label="Логин"
        />
        <InputController
          control={control}
          name="password"
          label="Пароль"
          type="password"
        />
        <Button
          htmlType="submit"
          type="primary"
          block
          loading={isLoading || isGetMyPermissionsFetching}
          style={{ marginTop: 20 }}
        >
          Войти
        </Button>
      </div>
    </form>
  );
};

export default LoginForm;
