import React from 'react';
import cn from 'classnames';

import styles from './BanUser.module.scss';
import { Button, Dropdown } from 'antd';
import { useGetSuspensionReasonsQuery } from '../../../store/api/endpoints/dicts';
import { useBanUserMutation } from '../../../store/api/endpoints/users';

const BanUser = ({ userId }) => {
  const {
    data: reasons,
    isLoading: isReasonsLoading,
  } = useGetSuspensionReasonsQuery({ page: -1 });

  const [
    banUser,
    { isLoading: isBanning },
  ] = useBanUserMutation();

  const handleClickReason = async (e) => {
    try {
      const data = {
        suspensionReasonId: e.key,
      };

      await banUser({ userId, data }).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dropdown
      loading={isBanning}
      menu={{
        items: [{
          key: '1',
          type: 'group',
          label: 'Выберите причину',
          children: reasons?.results.map(({ id, sysName }) => ({
            key: id,
            label: sysName,
            onClick: handleClickReason,
          }))
        }],
      }}
      trigger={['click']}
      disabled={isReasonsLoading}
    >
      <Button block type="primary" danger>Забанить</Button>
    </Dropdown>
  );
};

export default BanUser;
