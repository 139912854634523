import React from 'react';
import cn from 'classnames';

import styles from './RepoCredentialList.module.scss';
import { useGetRepoCredentialsQuery } from '../../../store/api/endpoints/repoCredentials';
import useQueryString from '../../../hooks/useQueryString';
import TableWrapper from '../../shared/TableWrapper';
import { Empty, Spin } from 'antd';
import EditRepoCredentialsFormDrawer from '../EditRepoCredentialsFormDrawer';

const RepoCredentialList = () => {
  const { page = 1, pageSize = 50, ...restQuery } = useQueryString();

  const {
    data: repoCredentials,
    isLoading: isRepoCredentialsLoading,
  } = useGetRepoCredentialsQuery({ page, pageSize, ...restQuery });

  const columns = [
    {
      dataIndex: 'id',
      key: 'id',
      title: 'ID'
    },
    {
      dataIndex: 'username',
      key: 'username',
      title: 'Username'
    },
    {
      dataIndex: 'host',
      key: 'host',
      title: 'Host'
    },
    {
      dataIndex: 'id',
      key: 'actions',
      title: 'Действия',
      render: (id, raw) => (
        <EditRepoCredentialsFormDrawer initialValues={raw} />
      )
    },
  ];

  if (isRepoCredentialsLoading) return <Spin />;
  if (!repoCredentials) return <Empty description="Не получил данных" />

  return (
    <div className="paper">
      <TableWrapper
        columns={columns}
        total={repoCredentials.count}
        data={repoCredentials.results}
      />
    </div>
  );
};

export default RepoCredentialList;
