import React from 'react';
import { Tag, Typography } from 'antd';
import { filter, includes, map, pipe } from 'ramda';
import { LANGUAGES } from '../../constants';
// import cn from 'classnames';

const AvailableLanguagesView = ({ languages }) => {

  return (
    <div>
      <Typography.Text>Доступен на языках</Typography.Text> <br/>
      {
        pipe(
          filter(({ id }) => includes(id, languages)),
          map(({ id, label }) => <Tag key={id} color="blue">{label}</Tag>),
        )(LANGUAGES)
      }
    </div>
  );
};

export default AvailableLanguagesView;
