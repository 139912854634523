import React from 'react';
import { Typography } from 'antd';

const PaidStatus = ({ isFree }) => {
  const type = isFree ? 'success' : 'danger';
  const text = isFree ? 'БЕСПЛАТНЫЙ' : 'ПЛАТНЫЙ';

  return <Typography.Text strong type={type}>{text}</Typography.Text>;
};

export default PaidStatus;
