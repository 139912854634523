import { Navigate } from 'react-router';
import store from 'store';
import routes from '../routes';

const RequireAuth = ({ children }) => {
  const credentials = store.get('credentials');

  if (!credentials) return <Navigate to={routes.root()} replace />;
  return children;
};

export default RequireAuth;
