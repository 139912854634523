import React from 'react';
import { useParams } from 'react-router';
import { useGetLessonDetailsQuery } from '../../../store/api/endpoints/lessons';
import { Flex, Spin, Tabs, Typography } from 'antd';
import LessonPublicationStatus from '../../../containers/lessons/LessonPublicationStatus';
import LessonMainForm from '../../../containers/lessons/LessonMainForm';
import LessonTranslates from '../../../containers/lessons/LessonTranslates';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import TestList from '../../../containers/lessonTests/TestList';
import TheoryContainer from '../../../containers/lessonTheory/TheoryContainer';
import LessonMainData from '../../../containers/lessons/LessonMainData';
import PracticeContainer from '../../../containers/lessonPractice/PracticeContainer';

const LessonPage = () => {
  const { id } = useParams();

  const {
    data: lesson,
    isLoading: isLessonLoading,
  } = useGetLessonDetailsQuery(id);

  return (
    <Spin spinning={isLessonLoading}>
      <Flex>
        <Link
          to={routes.modules(lesson?.module)}
          style={{ fontSize: 20, marginBottom: 20 }}
        >
          В модуль
        </Link>
      </Flex>
      <Flex gap={12} justify="space-between">
        <Typography.Title style={{ marginTop: 0 }}>
          {lesson?.sysName}
        </Typography.Title>
        <LessonPublicationStatus lessonId={id} />
      </Flex>
      <Flex gap={12}>
        <div className="paper" style={{ width: '40%' }}>
          <LessonMainData lessonId={id} />
        </div>
        <div className="paper" style={{ width: '30%' }}>
          <LessonTranslates
            title="Переводимые поля на Русском"
            lessonId={id}
            languageKey="ru"
          />
        </div>
        <div className="paper" style={{ width: '30%' }}>
          <LessonTranslates
            title="Переводимые поля на Узбекском"
            lessonId={id}
            languageKey="uz"
          />
        </div>
      </Flex>
      <div className="paper" style={{ marginTop: 12 }}>
        <Tabs
          centered
          items={[
            {
              label: 'Теория',
              key: 'theory',
              children: <TheoryContainer lessonId={id} />,
            },
            {
              label: 'Тесты',
              key: 'tests',
              children: <TestList lessonId={id} />,
            },
            {
              label: 'Практика',
              key: 'practice',
              children: <PracticeContainer lessonId={id} />,
            },
          ]}
        />
      </div>
    </Spin>
  );
};

export default LessonPage;
