import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  mainLessonDataUi: {
    isEditing: false,
  },
  translatesUi: {
    ru: {
      isEditing: false,
    },
    uz: {
      isEditing: false,
    },
  }
};

const reducerPath = 'lessons';

const lessonsSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    openMainLessonDataForm(state) {
      state.mainLessonDataUi.isEditing = true;
    },
    closeMainLessonDataForm(state) {
      state.mainLessonDataUi.isEditing = false;
    },
    toggleMainLessonDataForm(state) {
      state.mainLessonDataUi.isEditing = !state.mainLessonDataUi.isEditing;
    },

    openLessonTranslatesForm(state, { payload: langKey }) {
      state.translatesUi[langKey].isEditing = true;
    },
    closeLessonTranslatesForm(state, { payload: langKey }) {
      state.translatesUi[langKey].isEditing = false;
    },
    toggleLessonTranslatesForm(state, { payload: langKey }) {
      state.translatesUi[langKey].isEditing = !state.translatesUi[langKey].isEditing;
    },
  },
});

export const {
  openMainLessonDataForm,
  closeMainLessonDataForm,
  toggleMainLessonDataForm,

  openLessonTranslatesForm,
  closeLessonTranslatesForm,
  toggleLessonTranslatesForm,
} = lessonsSlice.actions;

export const useMainLessonDataUi = () => {
  return useSelector((state) => state[reducerPath].mainLessonDataUi);
};

export const useLessonTranslatesUi = (langKey) => {
  return useSelector((state) => state[reducerPath].translatesUi[langKey]);
};

export default lessonsSlice;
