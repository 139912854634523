import apiRoutes from '../routes';
import { mainApi } from '../index';

mainApi.injectEndpoints({
  endpoints: (builder) => ({
    upload: builder.mutation({
      query: (data) => ({
        url: apiRoutes.uploadFile(),
        method: 'POST',
        data,
      }),
    })
  })
});

export const {
  useUploadMutation,
} = mainApi;
