import React from 'react';
import cn from 'classnames';
import { green } from '@ant-design/colors';

import styles from './QuestionPublicationStatusForm.module.scss';
import { Button } from 'antd';
import {
  usePublishLessonQuestionMutation,
  useUnpublishLessonQuestionMutation
} from '../../../store/api/endpoints/lessonTests';
import { toast } from 'react-toastify';

const QuestionPublicationStatusForm = ({
  isPublished,
  questionId,
}) => {
  const [
    publishQuestion,
    { isLoading: isPublishing },
  ] = usePublishLessonQuestionMutation();
  const [
    unpublishQuestion,
    { isLoading: isUnpublishing },
  ] = useUnpublishLessonQuestionMutation();

  const handlePublishQuestion = async () => {
    try {
      await publishQuestion(questionId).unwrap();
      toast.success('Опубликован');
    } catch (e) {

    }
  };

  const handleUnpublishQuestion = async () => {
    try {
      await unpublishQuestion(questionId).unwrap();
      toast.success('Снят с публикации');
    } catch (e) {

    }
  };

  return isPublished ? (
    <Button
      size="small"
      danger
      onClick={handleUnpublishQuestion}
      loading={isUnpublishing}
    >
      Снять с публикации
    </Button>
  ) : (
    <Button
      size="small"
      type="primary"
      color="red"
      style={{ backgroundColor: green.primary }}
      onClick={handlePublishQuestion}
      loading={isPublishing}
    >
      Опубликовать
    </Button>
  );
};

export default QuestionPublicationStatusForm;
