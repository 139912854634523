import React from 'react';
import cn from 'classnames';

import styles from './CreateUserFormDrawer.module.scss';
import { Button, Drawer } from 'antd';
import useToggle from '../../../hooks/useToggle';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import { useCreateUserMutation } from '../../../store/api/endpoints/users';
import { toast } from 'react-toastify';

const CreateUserFormDrawer = () => {
  const [open, openDrawer, closeDrawer] = useToggle();
  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();

  const {
    control,
    handleSubmit: submit,
    setError,
  } = useForm({
    defaultValues: {},
  });

  const handleSubmit = async (values) => {
    try {
      await createUser(values).unwrap();
      closeDrawer();
      toast.success('Пользователь создан');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer}>Создать</Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать пользователя"
        destroyOnClose
        maskClosable={false}
      >
        <form onSubmit={submit(handleSubmit)}>
          <InputController control={control} name="email" label="Электронная почта" loading={isCreating} />
          <InputController control={control} name="password" label="Пароль" type="password" loading={isCreating} />
          <Button block htmlType="submit" type="primary" loading={isCreating}>
            Сохранить
          </Button>
        </form>
      </Drawer>
    </>
  );
};

export default CreateUserFormDrawer;
