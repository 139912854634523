import React from 'react';
import cn from 'classnames';

import styles from './AnswerForm.module.scss';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import SwitchController from '../../shared/controllers/SwitchController';
import CheckableTagsController from '../../shared/controllers/CheckableTagsController';
import { LANGUAGES } from '../../../constants';
import { Button, Flex, Spin } from 'antd';
import MarkdownInputController from '../../shared/controllers/MarkdownInputController';
import { number, object, mixed, string, array, bool } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useAppendTranslatesToTestAnswerMutation,
  useCreateTestAnswerMutation, useEditAnswerMutation
} from '../../../store/api/endpoints/lessonTests';
import { toast } from 'react-toastify';
import InputNumberController from '../../shared/controllers/InputNumberController';

const answerSchema = object({
  question: mixed().required(),
  sysName: string().required(),
  availableLanguages: array().required().min(0),
  isCorrect: bool().required(),
  translates: object({
    ru: object({
      text: string().required(),
      comment: string().required(),
    }),
    uz: object({
      text: string().required(),
      comment: string().required(),
    }),
  }).required(),
});

const AnswerForm = ({
  mode,
  initialValues,
  onSuccess,
}) => {
  const [
    createAnswer,
    { isLoading: isCreating },
  ] = useCreateTestAnswerMutation();
  const [
    editAnswer,
    { isLoading: isEditing },
  ] = useEditAnswerMutation();
  const [
    appendAnswerTranslate,
    { isLoading: isTranslateAppending },
  ] = useAppendTranslatesToTestAnswerMutation();

  const {
    control,
    handleSubmit: submit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(answerSchema),
  });

  const handleCreate = async ({ translates: { ru, uz }, ...answerData }) => {
    try {
      const answer = await createAnswer(answerData).unwrap();
      await appendAnswerTranslate({ answerId: answer.id, languageKey: 'ru', data: ru }).unwrap();
      await appendAnswerTranslate({ answerId: answer.id, languageKey: 'uz', data: uz }).unwrap();
      toast.success('Создан');
      onSuccess();
    } catch (e) {

    }
  };

  const handleEdit = async ({ translates: { ru, uz }, id, ...answerData }) => {
    try {
      await editAnswer({ answerId: id, data: answerData }).unwrap();
      await appendAnswerTranslate({ answerId: id, languageKey: 'ru', data: ru }).unwrap();
      await appendAnswerTranslate({ answerId: id, languageKey: 'uz', data: uz }).unwrap();
      toast.success('Создан');
      onSuccess();
    } catch (e) {

    }
  };

  const operations = {
    EDIT: submit(handleEdit),
    CREATE: submit(handleCreate),
  };

  return (
    <Spin spinning={isCreating || isEditing || isTranslateAppending}>
      <form onSubmit={operations[mode]}>
        <CheckableTagsController label="Доступен на языках" control={control} name="availableLanguages" options={LANGUAGES} />
        <InputController label="Системное название" control={control} name="sysName" />
        <InputNumberController label="Порядковый номер" name="placeInOrder" control={control} />
        <SwitchController label="Правильный ответ?" control={control} name="isCorrect" justify="start" />
        <Flex gap={12} style={{ marginTop: 12 }}>
          <div style={{ width: '50%' }}>
            <MarkdownInputController label="Текст ответа на Русском" control={control} name="translates.ru.text" />
          </div>
          <div style={{ width: '50%' }}>
            <MarkdownInputController label="Текст ответа на Узбекском" control={control} name="translates.uz.text" />
          </div>
        </Flex>
        <Flex gap={12}>
          <div style={{ width: '50%' }}>
            <MarkdownInputController label="Комментарий ответа на Русском" control={control} name="translates.ru.comment" />
          </div>
          <div style={{ width: '50%' }}>
            <MarkdownInputController label="Комментарий ответа на Узбекском" control={control} name="translates.uz.comment" />
          </div>
        </Flex>
        <Button
          type="primary"
          htmlType="submit"
          block
        >
          Сохранить
        </Button>
      </form>
    </Spin>
  );
};

export default AnswerForm;
