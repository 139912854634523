import { useCallback, useState } from 'react';

const useToggle = (defaultValue = false) => {
  const [isOpen, setOpen] = useState(defaultValue);

  const open = useCallback(() => {
    setOpen(true);
  }, []);

  const close = useCallback(() => {
    setOpen(false);
  }, []);

  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return [isOpen, open, close, toggle];
};

export default useToggle;
