import React from 'react';
import cn from 'classnames';

import styles from './RemoveTariff.module.scss';
import { Button, Popconfirm } from 'antd';
import { useRemoveTariffMutation } from '../../../store/api/endpoints/tariffs';
import { toast } from 'react-toastify';

const RemoveTariff = ({ tariffId }) => {
  const [removeTariff, { isLoading: isRemoving }] = useRemoveTariffMutation();

  const handleRemove = async () => {
    try {
      await removeTariff(tariffId).unwrap();
      toast.success('Тариф удален');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Popconfirm
      title="Вы уверены?"
      onConfirm={handleRemove}
      okButtonProps={{ loading: isRemoving }}
      okText="Да, удалить"
      cancelText="Нет, отменить"
    >
      <Button
        type="primary"
        danger
        style={{ marginTop: 20 }}
      >
        Удалить
      </Button>
    </Popconfirm>
  );
};

export default RemoveTariff;
