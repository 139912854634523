import React, { useEffect } from 'react';
import cn from 'classnames';

import styles from './TopicList.module.scss';
import { useGetTopicsQuery } from '../../../store/api/endpoints/topics';
import useQueryString from '../../../hooks/useQueryString';
import { Button, Card, Empty, Flex, Pagination, Spin, Typography } from 'antd';
import MDEditor from '@uiw/react-md-editor';
import CloseTopic from '../CloseTopic';
import OpenTopic from '../OpenTopic';
import HideTopic from '../HideTopic';
import UnhideTopic from '../UnhideTopic';
import ProcessTopic from '../ProcessTopic';
import { Link } from 'react-router-dom';
import routes from '../../../router/routes';
import { useDispatch } from 'react-redux';
import { selectTopic } from '../../../store/slicers/topicsSlice';
import { useNavigate } from 'react-router';
import usePaginationQueries from '../../../hooks/usePaginationQueries';
import queryString from 'query-string';

const TopicList = () => {
  const { page = 1, pageSize = 20, ...restQuery } = useQueryString();
  const dispatch = useDispatch();

  const {
    data: topics,
    isLoading: isTopicsLoading,
  } = useGetTopicsQuery({ page, pageSize, ...restQuery });

  const navigate = useNavigate();

  const handlePageChange = (page) => {
    navigate({
      pathname: '',
      search: queryString.stringify({ ...restQuery, page }),
    });
  };

  if (isTopicsLoading) return <Spin spinning />;
  if (!topics) return <Empty description="Не удалось получить данные топ" />


  return (
    <div data-color-mode="light">
      <div>
        {
          topics.results.map((topic) => (
            <Card
              key={topic.id}
              size="small"
              style={{ marginBottom: 14 }}
              actions={[
                topic.isClosed ? <OpenTopic id={topic.id} /> : <CloseTopic id={topic.id} />,
                topic.isVisible ? <HideTopic id={topic.id} /> : <UnhideTopic id={topic.id} />,
                <ProcessTopic id={topic.id} />,
              ]}
            >
              <Typography.Title
                level={5}
                style={{ marginTop: 0 }}
              >
                <Link to={routes.users(topic.author?.id)}>
                  {topic.author?.lastName} {topic.author?.firstName} -
                  <Typography.Text code>{topic.author?.username}</Typography.Text>
                </Link>
              </Typography.Title>
              <MDEditor
                theme="light"
                value={topic.text}
                fullscreen={false}
                overflow={false}
                preview="preview"
                hideToolbar
              />
              <Flex justify="flex-end" style={{ marginTop: 16 }}>
                <Button
                  type="primary"
                  onClick={() => {
                    dispatch(selectTopic(topic.id))
                  }}
                >
                  Показать комменты
                </Button>
              </Flex>
            </Card>
          ))
        }
      </div>
      <div style={{ marginTop: 16 }}>
        <Pagination
          current={page}
          pageSize={pageSize}
          total={topics.count}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default TopicList;
