import React from 'react';
import cn from 'classnames';

import styles from './Tags.module.scss';
import { Flex, Typography } from 'antd';
import TagList from '../../../containers/tags/TagList';
import TagDetails from '../../../containers/tags/TagDetails';
import TagCreationFormDrawer from '../../../containers/tags/TagCreationFormDrawer';

const Tags = () => {

  return (
    <div>
      <Flex justify="end" style={{ marginBottom: 12 }}>
        <TagCreationFormDrawer />
      </Flex>
      <Flex gap={12} vertical={false}>
        <div style={{ width: '50%' }} className="paper">
          <Typography.Title level={4} style={{ marginTop: 0 }}>Тэги</Typography.Title>
          <TagList />
        </div>
        <div style={{ width: '50%' }}>
          <TagDetails />
        </div>
      </Flex>
    </div>
  );
};

export default Tags;
