
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  selected: null,
};

const reducerPath = 'topics';

const topicsSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    selectTopic(state, { payload }) {
      state.selected = payload;
    },
    clearSelectedTopic(state) {
      state.selected = null;
    },
  },
});

export const {
  selectTopic,
  clearSelectedTopic,
} = topicsSlice.actions;

export const useSelectedTopic = () => {
  return useSelector((state) => state[reducerPath].selected);
};

export default topicsSlice;
