import React from 'react';
import { useHideCommentMutation } from '../../../store/api/endpoints/topics';
import { Button } from 'antd';

const HideComment = ({ id }) => {
  const [
    hideComment,
    { isLoading },
  ] = useHideCommentMutation();

  const handleClick = async () => {
    try {
      await hideComment(id).unwrap();
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <Button
      type="text"
      danger
      onClick={handleClick}
      loading={isLoading}
    >
      Скрыть от публики
    </Button>
  );
};

export default HideComment;
