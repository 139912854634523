import React from 'react';
import { Button, Drawer } from 'antd';
import { FiEdit2 } from 'react-icons/fi';
import useToggle from '../../../hooks/useToggle';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import { useUpdateRepoCredentialsMutation } from '../../../store/api/endpoints/repoCredentials';

const EditRepoCredentialsFormDrawer = ({ initialValues }) => {
  const [open, openDrawer, closeDrawer] = useToggle();

  const {
    control,
    handleSubmit: submit,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: initialValues,
  });

  const [
    editRepoCredentials,
    { isLoading: isEditing },
  ] = useUpdateRepoCredentialsMutation();

  const handleSubmit = async (values) => {
    try {
      const { id, ...data } = values;
      await editRepoCredentials({ id, data }).unwrap();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button icon={<FiEdit2 />} type="text" onClick={openDrawer} />
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Редактировать креденшелы реп"
        destroyOnClose
        maskClosable={false}
      >
        <form onSubmit={submit(handleSubmit)}>
          <InputController control={control} name="username" label="Username" />
          <InputController control={control} name="password" label="Пароль/Токен" />
          <Button block type="primary" htmlType="submit" loading={isEditing}>
            Сохранить
          </Button>
        </form>
      </Drawer>
    </>
  );
};

export default EditRepoCredentialsFormDrawer;
