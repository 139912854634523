import React from 'react';
import cn from 'classnames';

import styles from './TariffMainForm.module.scss';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import InputNumberController from '../../shared/controllers/InputNumberController';
import { Button, Col, Flex, Row } from 'antd';
import MarkdownInputController from '../../shared/controllers/MarkdownInputController';
import UploadController from '../../shared/controllers/UploadController';

const TariffMainForm = ({
  onSubmit,
  initialValues,
  loading,
}) => {

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: initialValues,
  });

  const handleSubmit = async (values) => {
    onSubmit(values);
  };

  return (
    <form onSubmit={submit(handleSubmit)}>
      <InputController control={control} name="sysName" label="Системное название" />
      <InputNumberController control={control} name="price" label="Цена" />
      <InputNumberController control={control} name="daysCount" label="Количество дней" />
      <InputNumberController control={control} name="placeInOrder" label="Порядковый номер" />
      <Row gutter={16}>
        <Col md={12}>
          <InputController control={control} name="name.ru" label="Название тарифа (на русском)" />
          <MarkdownInputController control={control} name="description.ru" label="Описание тарифа (на русском)"/>
          <UploadController control={control} name="picture.ru" label="Изображение тарифа (на русском)" group="tariff_images"/>
        </Col>
        <Col md={12}>
          <InputController control={control} name="name.uz" label="Название тарифа (на узбекском)" />
          <MarkdownInputController control={control} name="description.uz" label="Описание тарифа (на узбекском)"/>
          <UploadController control={control} name="picture.uz" label="Изображение тарифа (на узбекском)" group="tariff_images"/>
        </Col>
      </Row>
      <Button htmlType="submit" type="primary" block loading={loading}>Сохранить</Button>
    </form>
  );
};

export default TariffMainForm;
