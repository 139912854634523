import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getRepoCredentials: build.query({
      query: (params) => ({
        url: routes.repoCredentials(),
        params,
      }),
      providesTags: ['REPO_CREDENTIALS'],
    }),
    createRepoCredentials: build.mutation({
      query: (data) => ({
        url: routes.repoCredentials(),
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['REPO_CREDENTIALS'] }),
    }),
    updateRepoCredentials: build.mutation({
      query: ({ id, data }) => ({
        url: routes.repoCredentials(id),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['REPO_CREDENTIALS'] }),
    }),
  }),
});

export const {
  useGetRepoCredentialsQuery,
  useCreateRepoCredentialsMutation,
  useUpdateRepoCredentialsMutation,
} = mainApi;
