import { joinExisting } from '../../utils';

const routes = {
  login: () => joinExisting(['/api/v1/account/public/login/password']),
  refreshToken: () => '/api/v1/account/public/refresh-token',
  me: () => joinExisting(['/api/v1/account/user/me']),
  modules: (id) => joinExisting(['/api/v1/lesson/admin/modules', id]),
  moduleTranslates: (id, languageKey) => joinExisting(['/api/v1/lesson/admin/modules', id, 'translates', languageKey]),
  modulePublication: (id) => joinExisting(['/api/v1/lesson/admin/modules', id, 'publication-status']),

  tags: (id) => joinExisting(['/api/v1/display-lesson/admin/tags', id]),
  tagToModule: (tagId, moduleId) => joinExisting(['/api/v1/display-lesson/admin/tags', tagId, 'modules', moduleId]),

  lessons: (id) => joinExisting(['/api/v1/lesson/admin/lessons', id]),
  lessonTranslates: (lessonId, languageKey) => joinExisting(['/api/v1/lesson/admin/lessons', lessonId, 'translates', languageKey]),
  lessonPublication: (lessonId) => joinExisting(['/api/v1/lesson/admin/lessons', lessonId, 'publication-status']),

  lessonQuestions: (id) => joinExisting(['/api/v1/lesson-test/admin/questions', id]),
  lessonQuestionTranslates: (questionId, languageKey) => joinExisting(['/api/v1/lesson-test/admin/questions', questionId, 'translates', languageKey]),
  lessonQuestionPublication: (questionId) => joinExisting(['/api/v1/lesson-test/admin/questions', questionId, 'publication-status']),
  lessonTestAnswers: (id) => joinExisting(['/api/v1/lesson-test/admin/answers', id]),
  lessonTestAnswerTranslates: (answerId, languageKey) => joinExisting(['/api/v1/lesson-test/admin/answers', answerId, 'translates', languageKey]),

  lessonTheory: (id) => joinExisting(['/api/v1/theory/admin/theories', id]),
  lessonTheoryTranslates: (theoryId, languageKey) => joinExisting(['/api/v1/theory/admin/theories', theoryId, 'translates', languageKey]),
  lessonTheoryPublication: (theoryId) => joinExisting(['/api/v1/theory/admin/theories', theoryId, 'publication-status']),
  lessonTheoryVideos: (theoryId, languageKey) => joinExisting(['/api/v1/theory/admin/theories', theoryId, 'translates', languageKey, 'video']),

  questionTypes: () => joinExisting(['/api/v1/lesson-test/public/dicts/question-types']),

  lessonPractices: (id) => joinExisting(['/api/v1/lesson-practice/admin/lesson-practices', id]),
  lessonPracticePublication: (id) => joinExisting(['/api/v1/lesson-practice/admin/lesson-practices', id, 'publication-status']),
  lessonPracticeTranslates: (id, languageKey) => joinExisting(['/api/v1/lesson-practice/admin/lesson-practices', id, 'translates', languageKey]),

  trainerTemplates: () => joinExisting(['/api/v1/lesson-practice/admin/trainer-templates']),

  users: (id) => joinExisting(['/api/v1/account/admin/users', id]),
  usersPermission: (id) => joinExisting(['/api/v1/account/admin/users', id, 'permissions']),
  usersPassword: (id) => joinExisting(['/api/v1/account/admin/users', id, 'password']),
  usersBan: (id) => joinExisting(['/api/v1/account/admin/users', id, 'ban']),
  usersUnban: (id) => joinExisting(['/api/v1/account/admin/users', id, 'unban']),

  tariffs: (id) => joinExisting(['/api/v1/subscription/admin/tariffs', id]),
  tariffPublication: (id) => joinExisting(['/api/v1/subscription/admin/tariffs', id, 'publication-status']),

  subscriptions: (id) => joinExisting(['/api/v1/subscription/admin/subscriptions', id]),
  cancelSubscription: (id) => joinExisting(['/api/v1/subscription/admin/subscriptions', id, 'reject']),

  repoCredentials: (id) => joinExisting(['/api/v1/container-file/admin/repository-credentials', id]),

  topics: () => joinExisting(['/api/v1/discussion/admin/topics']),
  closeTopic: (id) => joinExisting(['/api/v1/discussion/admin/topics', id, 'close']),
  openTopic: (id) => joinExisting(['/api/v1/discussion/admin/topics', id, 'open']),
  visibilityTopic: (id) => joinExisting(['/api/v1/discussion/admin/topics', id, 'visibility']),
  processTopic: (id) => joinExisting(['/api/v1/discussion/admin/topics', id, 'process']),
  comments: () => joinExisting(['/api/v1/discussion/admin/comments']),
  markCommentSolution: (id) => joinExisting(['/api/v1/discussion/admin/comments', id, 'solution']),
  visibilityComment: (id) => joinExisting(['/api/v1/discussion/admin/comments', id, 'visibility']),

  uploadFile: () => joinExisting(['/api/v1/public-file/content-admin/files']),

  suspensionReasons: () => joinExisting(['/api/v1/account/admin/dicts/suspension-reasons']),
};

export default routes;
