import React from 'react';
import cn from 'classnames';

import styles from './Topics.module.scss';
import TopicList from '../../../containers/topics/TopicList';
import { Col, Row } from 'antd';
import CommentsDrawer from '../../../containers/topics/CommentsDrawer';

const Topics = () => {

  return (
    <Row gutter={12}>
      <Col md={24}>
        <TopicList />
      </Col>
      <CommentsDrawer />
    </Row>
  );
};

export default Topics;
