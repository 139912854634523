import React, { useState } from 'react';
import cn from 'classnames';

import styles from './CancelUserSubscription.module.scss';
import { Button, Input, Popconfirm, Space } from 'antd';
import { useCancelSubscriptionMutation } from '../../../store/api/endpoints/subscriptions';
import { toast } from 'react-toastify';

const CancelUserSubscription = ({ subscriptionId }) => {
  const [rejectedReason, setRejectedReason] = useState('');
  const [
    cancelSubscription,
    { isLoading: isCanceling },
  ] = useCancelSubscriptionMutation();

  const handleConfirm = async () => {
    try {
      const data = {
        rejectedReason,
      };
      await cancelSubscription({ subscriptionId, data }).unwrap();
      toast.success('Подписка отменена');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Popconfirm
      title="Вы уверены?"
      okText="Да, отменить подписку"
      cancelText="Нет"
      onConfirm={handleConfirm}
    >
      <Space.Compact>
        <Input placeholder="Причина отмены" value={rejectedReason} onChange={(e) => setRejectedReason(e.target.value)} />
        <Button danger type="primary">Отменить подписку</Button>
      </Space.Compact>
    </Popconfirm>
  );
};

export default CancelUserSubscription;
