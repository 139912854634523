import React from 'react';
import { Button, Popconfirm } from 'antd';
import { FiTrash2 } from 'react-icons/fi';
import { useDeleteAnswerMutation } from '../../../store/api/endpoints/lessonTests';
import { toast } from 'react-toastify';

const DeleteAnswer = ({ answerId }) => {
  const [deleteAnswer, { isLoading }] = useDeleteAnswerMutation();
  const handleOk = async () => {
    try {
      await deleteAnswer(answerId).unwrap();
      toast.success('Удалено');
    } catch (e) {

    }
  };

  return (
    <Popconfirm
      onConfirm={handleOk}
      title="Вы уверены?"
      okText="Да"
      cancelText="Отменить"
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Button type="text" icon={<FiTrash2 />} danger />
    </Popconfirm>
  );
};

export default DeleteAnswer;
