import React from 'react';
import cn from 'classnames';

import styles from './PracticeTranslatesContainer.module.scss';
import { Button, Card, Col, Empty, Row, Space, Spin, Typography } from 'antd';
import { FiEdit2 } from 'react-icons/fi';
import { useGetLessonPracticeDetailsQuery } from '../../../store/api/endpoints/lessonPractice';
import PracticeTranslatesFormDrawer from '../PracticeTranslatesFormDrawer';
import MDEditor from '@uiw/react-md-editor';

const PracticeTranslatesContainer = ({
  languageKey,
  title,
  practiceId,
}) => {
  const {
    data: practice,
    isLoading: isPracticeLoading,
  } = useGetLessonPracticeDetailsQuery(practiceId);

  if (isPracticeLoading) return (
    <Spin spinning />
  );

  const currentData = practice?.translates?.[languageKey];

  if (!currentData) return (
    <Empty description="Переводы еще не были добавлены">
      <PracticeTranslatesFormDrawer
        btnText="Добавить"
        languageKey={languageKey}
        practiceId={practiceId}
      />
    </Empty>
  )

  return (
    <Card size="small">
      <Row justify="space-between">
        <Col>
          <Typography.Title level={4} style={{ marginTop: 0 }}>{title}</Typography.Title>
        </Col>
        <Col>
          <PracticeTranslatesFormDrawer
            btnText="Редактировать"
            languageKey={languageKey}
            practiceId={practiceId}
            initialValues={currentData}
          />
        </Col>
      </Row>

      <Space direction="vertical" style={{ width: '100%' }}>
        <div>
          <Typography.Text>Ответ</Typography.Text> <br/>
          <MDEditor
            theme="light"
            value={currentData.answer}
            fullscreen={false}
            overflow={false}
            preview="preview"
            hideToolbar
          />
        </div>

        <div>
          <Typography.Text>Подсказка</Typography.Text> <br/>
          <MDEditor
            theme="light"
            value={currentData.help}
            fullscreen={false}
            overflow={false}
            preview="preview"
            hideToolbar
          />
        </div>

        <div>
          <Typography.Text>Описание</Typography.Text> <br/>
          <MDEditor
            theme="light"
            value={currentData.description}
            fullscreen={false}
            overflow={false}
            preview="preview"
            hideToolbar
          />
        </div>
      </Space>
    </Card>
  );
};

export default PracticeTranslatesContainer;
