import React from 'react';
import cn from 'classnames';

import styles from './TagMainForm.module.scss';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import { Button, Flex } from 'antd';
import SwitchController from '../../shared/controllers/SwitchController';
import InputNumberController from '../../shared/controllers/InputNumberController';
import UploadController from '../../shared/controllers/UploadController';

const TagMainForm = ({
  initialValues,
  onSubmit,
  isLoading,
}) => {

  const rootInitialValues = {
    name: {
      ru: null,
      uz: null,
    },
    description: {
      ru: null,
      uz: null,
    },
    image: {
      ru: null,
      uz: null,
    },
    slug: null,
    isPromoted: false,
    placeInOrder: 1,
  };

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: initialValues ?? rootInitialValues,
    values: initialValues ?? rootInitialValues,
  });

  return (
    <form onSubmit={submit(onSubmit)}>
      <Flex gap={10} justify="space-between" wrap="wrap">
        <InputNumberController control={control} name="placeInOrder" label="Порядковый номер" />
        <InputController control={control} name="slug" label="Slug" />
        <SwitchController control={control} name="isPromoted" label="Продвигается?" />
      </Flex>
      <Flex vertical={false} gap={10} justify="space-between">
        <div style={{ width: '50%' }}>
          <InputController control={control} name="name.ru" label="Имя на русском" />
        </div>
        <div style={{ width: '50%' }}>
          <InputController control={control} name="name.uz" label="Имя на узбекском" />
        </div>
      </Flex>
      <Flex vertical={false} gap={10} justify="space-between">
        <div style={{ width: '50%' }}>
          <InputController control={control} name="description.ru" label="Описание на русском" />
        </div>
        <div style={{ width: '50%' }}>
          <InputController control={control} name="description.uz" label="Описание на узбекском" />
        </div>
      </Flex>
      <Flex gap={10}>
        <UploadController name="image.ru" label="Изображение на русском" control={control} group="tags_images" />
        <UploadController name="image.uz" label="Изображение на узбекском" control={control} group="tags_images" />
      </Flex>
      <div style={{ textAlign: 'right' }}>
        <Button htmlType="submit" type="primary" loading={isLoading}>
          Сохранить
        </Button>
      </div>
    </form>
  );
};

export default TagMainForm;
