import React from 'react';
import cn from 'classnames';

import styles from './UserPage.module.scss';
import { useParams } from 'react-router';
import { useGetUserDetailsQuery } from '../../../store/api/endpoints/users';
import { Alert, Col, Empty, Flex, Row, Space, Spin, Tag, Typography } from 'antd';
import UsersPermissions from '../../../containers/users/UsersPermissions';
import EditUserFormDrawer from '../../../containers/users/EditUserFormDrawer';
import { formatDate } from '../../../utils';
import ChangeUserPassword from '../../../containers/users/ChangeUserPassword';
import BanUser from '../../../containers/users/BanUser';
import UnbanUser from '../../../containers/users/UnbanUser';
import UserSubscriptions from '../../../containers/users/UserSubscriptions';

const UserPage = () => {
  const { id } = useParams();

  const {
    data: user,
    isLoading: isUserLoading,
  } = useGetUserDetailsQuery(id, { skip: !id });

  if (isUserLoading) return <Spin spinning />
  if (!user) return <Empty description={`Данных пользователя ${id} нет`} />

  return (
    <div>
      <Row gutter={12}>
        <Col md={9}>
          <div className="paper" style={{ height: '100%' }}>
            {
              user.isSuspended
                ? (
                  <Alert
                    type="error"
                    showIcon
                    message="Пользователь забанен"
                    description={`Причина: ${user.suspensionReason?.sysName}`}
                    style={{ marginBottom: 20 }}
                    action={
                      <UnbanUser userId={id} />
                    }
                  />
                ) : null
            }
            {
              user.profilePhoto?.url && (
                <div style={{ textAlign: 'center' }}>
                  <img src={user.profilePhoto?.url} style={{ width: '30%' }}  alt="user photo"/>
                </div>
              )
            }
            <Typography.Title level={4} style={{ marginTop: 10 }}>{user.lastName} {user.firstName} {user.secondName}</Typography.Title>
            <Space direction="vertical">
              <div>
                <Typography.Text type="secondary">Почта</Typography.Text> <br />
                <Typography.Text bold>{user.email || '-'}</Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">Username</Typography.Text> <br />
                <Typography.Text bold>{user.username || '-'}</Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">Дата рождения</Typography.Text> <br />
                <Typography.Text bold>{formatDate(user.birthdate) || '-'}</Typography.Text>
              </div>
              <div>
                <Typography.Text type="secondary">Установлен пароль?</Typography.Text> <br />
                <Typography.Text bold type={user.hasPassword ? 'success' : 'danger'}>{user.hasPassword ? 'Да' : 'Нет'}</Typography.Text>
              </div>
            </Space>
          </div>
        </Col>
        <Col md={9}>
          <div className="paper" style={{ height: '100%' }}>
            <UsersPermissions
              userId={id}
              permissions={user.permissions}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="paper" style={{ height: '100%' }}>
            <Typography.Title level={4} style={{ marginTop: 0 }}>Действия</Typography.Title>
            <Space direction="vertical" style={{ width: '100%' }}>
              <EditUserFormDrawer userId={id} />
              <ChangeUserPassword userId={id} />
              {
                user.isSuspended
                  ? null
                  : (
                    <BanUser userId={id} />
                  )
              }
            </Space>
          </div>
        </Col>
      </Row>

      <Row gutter={12} style={{ marginTop: 12 }}>
        <Col md={24}>
          <div className="paper">
            <Typography.Title level={4} style={{ marginTop: 0 }}>Подписки пользователя</Typography.Title>

            <UserSubscriptions userId={id} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserPage;
