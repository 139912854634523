import React from 'react';
import { Button, Upload } from 'antd';
import { useController } from 'react-hook-form';
import { FiUpload } from 'react-icons/fi';

const VideoPickerController = ({
  control,
  name,
  ...props
}) => {
  const { field, fieldState } = useController({
    name, control,
  });

  const customUploadRequest = async ({ onSuccess, onError, file }) => {
    field.onChange(file);
    onSuccess(file);
  };

  return (
    <Upload
      customRequest={customUploadRequest}
      multiple={false}
      accept="video/*"
      onRemove={() => {
        field.onChange(null);
      }}
      {...props}
    >
      <Button icon={<FiUpload />}>Нажмите чтобы загрузить</Button>
    </Upload>
  );
};

export default VideoPickerController;
