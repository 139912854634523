import React from 'react';
import TableWrapper from '../../shared/TableWrapper';
import { useGetLessonsQuery } from '../../../store/api/endpoints/lessons';
import usePaginationQueries from '../../../hooks/usePaginationQueries';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';

const LessonList = ({ moduleId }) => {
  const paginationQuery = usePaginationQueries();
  const query = useQueryString();
  const navigate = useNavigate();

  const {
    data: lessonData,
    isFetching: isLessonDataFetching,
  } = useGetLessonsQuery({ ...query, ...paginationQuery, module: moduleId });

  const columns = [
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
    },
    {
      key: 'placeInOrder',
      dataIndex: 'placeInOrder',
      title: 'Порядковый номер',
      width: 120,
    },
    {
      key: 'sysName',
      dataIndex: 'sysName',
      title: 'Название',
    },
    {
      key: 'isPublished',
      dataIndex: 'isPublished',
      title: 'Опубликован?',
      width: 120,
      render: (isPublished) => isPublished ? '✅' : '❌',
    },
  ];

  return (
    <TableWrapper
      columns={columns}
      data={lessonData?.results}
      total={lessonData?.count}
      loading={isLessonDataFetching}
      rowKey="id"
      onRow={(row) => ({
        onClick: () => {
          navigate(routes.lessons(row.id));
        },
      })}
    />
  );
};

export default LessonList;
