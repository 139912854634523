import React from 'react';
import useToggle from '../../../hooks/useToggle';
import { Button, Drawer } from 'antd';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import DateInputController from '../../shared/controllers/DateInputController';
import UploadController from '../../shared/controllers/UploadController';
import { useEditUserMutation, useGetUserDetailsQuery } from '../../../store/api/endpoints/users';
import dayjs from 'dayjs';

const EditUserFormDrawer = ({ userId }) => {
  const [open, openDrawer, closeDrawer] = useToggle(false);

  const [
    editUser,
    { isLoading: isEditing },
  ] = useEditUserMutation();

  const {
    data: user,
  } = useGetUserDetailsQuery(userId, { skip: !userId });

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: {
      ...user,
      birthdate: user.birthdate ? dayjs(user.birthdate) : null,
    },
  });

  const handleSubmit = async (values) => {
    try {
      const data = {
        ...values,
        birthdate: values.birthdate?.format('YYYY-MM-DD'),
      }
      await editUser({ userId, data }).unwrap();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer} block>Редактировать</Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Редактировать пользователя"
        destroyOnClose
        maskClosable={false}
        width="50%"
      >
        <form onSubmit={submit(handleSubmit)}>
          <UploadController control={control} name="profilePhoto" label="Фото пользователя" group="users_photo"/>
          <InputController control={control} name="username" label="Username" />
          <InputController control={control} name="lastName" label="Фамилия" />
          <InputController control={control} name="firstName" label="Имя" />
          <InputController control={control} name="secondName" label="Отчество" />
          <InputController control={control} name="email" label="Email" />
          <DateInputController control={control} name="birthdate" label="Дата рождения" />
          <Button htmlType="submit" type="primary" block loading={isEditing}>Сохранить</Button>
        </form>
      </Drawer>
    </>
  );
};

export default EditUserFormDrawer;
