import React from 'react';
import cn from 'classnames';

import styles from './PracticeContainer.module.scss';
import {
  useGetLessonPracticeDetailsQuery,
  useGetLessonPracticesQuery, useGetTrainerTemplatesQuery
} from '../../../store/api/endpoints/lessonPractice';
import { Button, Col, Divider, Empty, Row, Space, Spin, Typography } from 'antd';
import CreatePracticeDrawer from '../CreatePracticeDrawer';
import { head } from 'ramda';
import AvailableLanguagesView from '../../shared/AvailableLanguagesView';
import PracticePublicationStatus from '../PracticePublicationStatus';
import { FiEdit2 } from 'react-icons/fi';
import EditPracticeDrawer from '../EditPracticeDrawer';
import PracticeTranslatesContainer from '../PracticeTranslatesContainer';

const PracticeContainer = ({ lessonId }) => {
  const {
    data: practices,
    isLoading: isPracticesLoading,
  } = useGetLessonPracticesQuery({ lesson: lessonId }, { skip: !lessonId });

  const {
    data: trainerTemplates,
  } = useGetTrainerTemplatesQuery();


  const hasPractice = practices && practices.count > 0;
  const practice = hasPractice ? head(practices.results) : null;

  const {
    data: practiceDetails,
    isLoading: isPracticeDetailsLoading,
  } = useGetLessonPracticeDetailsQuery(practice?.id, { skip: !hasPractice });

  if (isPracticeDetailsLoading || isPracticesLoading) return (
    <Spin spinning />
  );
  if (!hasPractice) return (
    <Empty description="В уроке нет практики">
      <CreatePracticeDrawer lessonId={lessonId} />
    </Empty>
  );

  if (!practiceDetails) return (
    <div>Запрос по уроку = {lessonId} ничего не вернул</div>
  );

  return (
    <div>
      <Row gutter={24}>
        <Col md={24}>
          <Row justify="space-between">
            <Col>
              <Typography.Title level={4} style={{ marginTop: 0 }}>Практика</Typography.Title>
            </Col>
            <Col>
              <EditPracticeDrawer practiceId={practiceDetails.id} />
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <AvailableLanguagesView languages={practiceDetails.availableLanguages} />
          <Space direction="vertical" style={{ marginTop: 20 }}>
            <div>
              <Typography.Text>Системное название</Typography.Text> <br/>
              <Typography.Text strong>{practiceDetails.sysName}</Typography.Text>
            </div>
            <div>
              <Typography.Text>Репозиторий</Typography.Text> <br/>
              <Typography.Text strong>
                <a href={practiceDetails.gitRepositoryUrl} target="_blank">{practiceDetails.gitRepositoryUrl}</a>
                {' '}
                ({practiceDetails.gitRepositoryBranch})
              </Typography.Text>
            </div>
            <div>
              <Typography.Text>Шаблон тренажера</Typography.Text> <br/>
              <Typography.Text strong>
                {trainerTemplates?.results.find(({ id }) => id === practiceDetails.trainerTemplate)?.sysName}
              </Typography.Text>
            </div>
          </Space>
        </Col>
        <Col md={12}>
          <PracticePublicationStatus practiceId={practiceDetails.id} />
          <Space direction="vertical" style={{ marginTop: 20 }}>
            <div>
              <Typography.Text>Команда для запуска тестов</Typography.Text> <br/>
              <Typography.Text code>{practiceDetails.testCommand}</Typography.Text>
            </div>
            <div>
              <Typography.Text>Директория с тестами</Typography.Text> <br/>
              <Typography.Text code>{practiceDetails.testCommandWorkdir}</Typography.Text>
            </div>
          </Space>
        </Col>
      </Row>

      <Divider />

      <Row gutter={24}>
        <Col md={12}>
          <PracticeTranslatesContainer
            practiceId={practiceDetails.id}
            languageKey="ru"
            title="Переводимые поля на Русском"
          />
        </Col>
        <Col md={12}>
          <PracticeTranslatesContainer practiceId={practiceDetails.id} languageKey="uz" title="Переводимые поля на Узбекском" />
        </Col>
      </Row>
    </div>
  );
};

export default PracticeContainer;
