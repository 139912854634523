import React from 'react';
import cn from 'classnames';

import styles from './PublicationStatusForm.module.scss';
import { Button, Flex, Typography } from 'antd';
import { green } from '@ant-design/colors';
import {
  usePublishLessonTheoryMutation,
  useUnpublishLessonTheoryMutation
} from '../../../store/api/endpoints/lessonTheory';

const PublicationStatusForm = ({ isPublished, theoryId }) => {
  const [
    publishTheory,
    { isLoading: isPublishing },
  ] = usePublishLessonTheoryMutation();
  const [
    unpublishTheory,
    { isLoading: isUnpublishing },
  ] = useUnpublishLessonTheoryMutation();

  const handlePublish = async () => {
    await publishTheory(theoryId);
  };

  const handleUnpublish = async () => {
    await unpublishTheory(theoryId);
  };

  return (
    <div>
      <Typography.Title style={{ marginTop: 0, marginBottom: 0 }} level={4} type={isPublished ? 'success' : 'danger'}>{isPublished ? 'Опубликован' : 'Черновик'}</Typography.Title>
      {
        isPublished
          ? <Button type="primary" danger onClick={handleUnpublish} loading={isUnpublishing}>В черновики</Button>
          : <Button type="primary" style={{ backgroundColor: green.primary }} onClick={handlePublish} loading={isPublishing}>Опубликовать</Button>
      }
    </div>
  );
};

export default PublicationStatusForm;
