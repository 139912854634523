import React from 'react';
import { Flex, Typography } from 'antd';
import ModuleList from '../../../containers/modules/ModuleList';
import ModuleCreationFormDrawer from '../../../containers/modules/ModuleCreationFormDrawer';

const Modules = () => {
  return (
    <Flex justify="space-between" gap={12} wrap="wrap" vertical>
      <Flex justify="end">
        <ModuleCreationFormDrawer />
      </Flex>
      <Flex justify="space-between" gap={12}>
        <div className="paper" style={{ width: '100%' }}>
          <Typography.Title level={4} style={{ marginTop: 0 }}>Модули</Typography.Title>
          <ModuleList />
        </div>
      </Flex>
    </Flex>
  );
};

export default Modules;
