import React from 'react';
import cn from 'classnames';

import styles from './UnbanUser.module.scss';
import { Button } from 'antd';
import { useUnbanUserMutation } from '../../../store/api/endpoints/users';

const UnbanUser = ({ userId }) => {
  const [
    unbanUser,
    { isLoading: isUnbanning },
  ] = useUnbanUserMutation();

  const handleUnban = async () => {
    try {
      await unbanUser(userId).unwrap();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Button
      danger
      type="primary"
      onClick={handleUnban}
      loading={isUnbanning}
    >
      Разбанить
    </Button>
  );
};

export default UnbanUser;
