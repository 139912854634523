import React from 'react';
import { Button, Spin, Upload } from 'antd';
import { useController } from 'react-hook-form';
import { useUploadMutation } from '../../../store/api/endpoints/files';
import { FiPlus } from 'react-icons/fi';

const UploadController = ({
  control,
  name,
  group = 'content-images',
  label,
  disabled,
  ...props
}) => {
  const { field, fieldState } = useController({
    name, control,
  });

  const [
    upload,
    { isLoading },
  ] = useUploadMutation();

  const customUploadRequest = async ({ onSuccess, onError, file }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('group', group);

    try {
      const data = await upload(formData).unwrap();
      field.onChange(data);
      onSuccess(data);
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Spin spinning={isLoading}>
      <Upload
        listType="picture-card"
        showUploadList={false}
        customRequest={customUploadRequest}
        disabled={isLoading || disabled}
        {...props}
      >
        {
          field.value
            ? <img src={field.value.url} alt="" style={{ width: '90%', borderRadius: 8 }}/>
            : (
              <div>
                <div>
                  <FiPlus />
                </div>
                <div>
                  {label}
                </div>
              </div>
            )
        }
      </Upload>
    </Spin>
  );
};

export default UploadController;
