import React from 'react';
import cn from 'classnames';

import styles from './LessonPublicationStatus.module.scss';
import { Button, Flex, Typography } from 'antd';
import {
  useGetLessonDetailsQuery,
  usePublishLessonMutation,
  useUnpublishLessonMutation
} from '../../../store/api/endpoints/lessons';

const LessonPublicationStatus = ({ lessonId }) => {
  const {
    data: lesson,
  } = useGetLessonDetailsQuery(lessonId);
  const [
    publishLesson,
    { isLoading: isPublishing },
  ] = usePublishLessonMutation();
  const [
    unpublishLesson,
    { isLoading: isUnpublishing },
  ] = useUnpublishLessonMutation();

  const handleClickUnpublish = async () => {
    await unpublishLesson(lessonId)
  };
  const handleClickPublish = async () => {
    await publishLesson(lessonId);
  };

  const style = { marginTop: 0 };

  return lesson?.isPublished
    ? (
      <Flex gap={10}>
        <Typography.Title type="success" level={3} style={style}>Опубликован</Typography.Title>
        <Button type="primary" danger onClick={handleClickUnpublish} loading={isUnpublishing}>
          Снять публикацию
        </Button>
      </Flex>
    ) : (
      <Flex gap={10}>
        <Typography.Title type="danger" level={4} style={style}>Не опубликован</Typography.Title>
        <Button type="primary" onClick={handleClickPublish} loading={isPublishing}>
          Опубликовать
        </Button>
      </Flex>
    )
};

export default LessonPublicationStatus;
