
import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

const initialState = {
  selected: null,
};

const reducerPath = 'tags';

const tagsSlice = createSlice({
  name: reducerPath,
  initialState,
  reducers: {
    selectTag(state, { payload }) {
      state.selected = payload;
    },
    resetSelectedTag(state) {
      state.selected = null;
    },
  },
});

export const {
  selectTag,
  resetSelectedTag,
} = tagsSlice.actions;

export const useSelectedTag = () => {
  return useSelector((state) => state[reducerPath].selected);
};

export default tagsSlice;
