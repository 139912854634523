import React from 'react';
import cn from 'classnames';

import styles from './TariffPage.module.scss';
import { useParams } from 'react-router';
import { useGetTariffDetailsQuery } from '../../../store/api/endpoints/tariffs';
import { Alert, Button, Col, Empty, Flex, Row, Space, Spin, Typography } from 'antd';
import { formatNumber } from '../../../utils';
import MDEditor from '@uiw/react-md-editor';
import TariffPublicationStatus from '../../../containers/tariffs/TariffPublicationStatus';
import RemoveTariff from '../../../containers/tariffs/RemoveTariff';
import EditTariffDrawer from '../../../containers/tariffs/EditTariffDrawer';

const TariffPage = () => {
  const { id } = useParams();
  const {
    data: tariff,
    isLoading: isTariffLoading,
  } = useGetTariffDetailsQuery(id);

  if (isTariffLoading) return <Spin spinning />
  if (!tariff) return <Empty description="По запросу не нашлось данных" />

  return (
    <div className="paper">
      {
        tariff.isDeleted
          ? <Alert type="error" description="Удалённый тариф" style={{ marginBottom: 20 }}/>
          : null
      }
      <Flex justify="space-between">
        <Typography.Title level={3} style={{ marginTop: 0 }}>{tariff.sysName}</Typography.Title>
        <TariffPublicationStatus tariffId={id} />
      </Flex>
      <Row gutter={40}>
        <Col>
          <Typography.Text type="secondary">Цена</Typography.Text><br/>
          <Typography.Text bold>{formatNumber(tariff.price)} сум</Typography.Text><br/>
        </Col>
        <Col>
          <Typography.Text type="secondary">Количество дней</Typography.Text><br/>
          <Typography.Text bold>{formatNumber(tariff.daysCount)}</Typography.Text><br/>
        </Col>
        <Col>
          <Typography.Text type="secondary">Порядковый номер</Typography.Text><br/>
          <Typography.Text bold>{formatNumber(tariff.placeInOrder)}</Typography.Text><br/>
        </Col>
      </Row>

      <Row style={{ marginTop: 20 }} gutter={20}>
        <Col md={12}>
          <Typography.Text type="secondary">Название на русском</Typography.Text><br/>
          <Typography.Text bold>{formatNumber(tariff.name?.ru)}</Typography.Text><br/>
        </Col>
        <Col md={12}>
          <Typography.Text type="secondary">Название на узбекском</Typography.Text><br/>
          <Typography.Text bold>{formatNumber(tariff.name?.uz)}</Typography.Text><br/>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }} gutter={20}>
        <Col md={12}>
          <Typography.Text type="secondary">Описание на русском</Typography.Text><br/>
          <MDEditor
            theme="light"
            value={tariff.description?.uz}
            fullscreen={false}
            overflow={false}
            preview="preview"
            hideToolbar
          />
        </Col>
        <Col md={12}>
          <Typography.Text type="secondary">Описание на узбекском</Typography.Text><br/>
          <MDEditor
            theme="light"
            value={tariff.description?.ru}
            fullscreen={false}
            overflow={false}
            preview="preview"
            hideToolbar
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }} gutter={20}>
        <Col md={12}>
          <Typography.Text type="secondary">Изображение на русском</Typography.Text><br/>
          <img src={tariff.picture?.ru?.url} alt="" style={{ width: '50%' }}/>
        </Col>
        <Col md={12}>
          <Typography.Text type="secondary">Изображение на узбекском</Typography.Text><br/>
          <img src={tariff.picture?.uz?.url} alt="" style={{ width: '50%' }}/>
        </Col>
      </Row>
      <Flex justify="flex-end">
        <Space>
          <EditTariffDrawer tariffId={id} />
          <RemoveTariff tariffId={id} />
        </Space>
      </Flex>
    </div>
  );
};

export default TariffPage;
