import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptions: build.query({
      query: (params) => ({
        url: routes.subscriptions(),
        params,
      }),
      providesTags: ['SUBSCRIPTIONS'],
    }),
    getSubscriptionDetails: build.query({
      query: (id) => ({
        url: routes.subscriptions(id),
      }),
      providesTags: ['SUBSCRIPTION_DETAILS'],
    }),
    createSubscription: build.mutation({
      query: (data) => ({
        url: routes.subscriptions(),
        data,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['SUBSCRIPTIONS'] }),
    }),
    cancelSubscription: build.mutation({
      query: ({ subscriptionId, data }) => ({
        url: routes.cancelSubscription(subscriptionId),
        data,
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['SUBSCRIPTIONS'] }),
    }),
  }),
});

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionDetailsQuery,
  useCreateSubscriptionMutation,
  useCancelSubscriptionMutation,
} = mainApi;
