import React from 'react';
import cn from 'classnames';

import styles from './ModuleTags.module.scss';
import {
  useAddTagToModuleMutation,
  useGetTagsQuery,
  useRemoveTagFromModuleMutation
} from '../../../store/api/endpoints/tags';
import { Empty, Flex, Spin, Tag } from 'antd';
import { includes, map } from 'ramda';
import TagCreationFormDrawer from '../../tags/TagCreationFormDrawer';

const ModuleTags = ({ moduleId }) => {
  const {
    data: tagsData,
    isFetching: isTagsFetching,
  } = useGetTagsQuery({ page: -1 });

  const {
    data: moduleTagsData,
    isFetching: isModuleTagsFetching,
  } = useGetTagsQuery({ page: -1, module: moduleId });

  const [
    addTagToModule,
  ] = useAddTagToModuleMutation();
  const [
    removeTagFromModule,
  ] = useRemoveTagFromModuleMutation();

  const handleChangeTag = (tag) => async (checked) => {
    if (checked) {
      await addTagToModule({ tag, moduleId });
    } else {
      await removeTagFromModule({ tag, moduleId });
    }
  };

  if (!tagsData) return <Empty description="Тэги не загрузились" />;
  if (!moduleTagsData) return <Empty description="Тэги этого модуля не загрузились" />

  return (
    <Spin spinning={isTagsFetching || isModuleTagsFetching}>
      <Flex vertical gap={6} align="start">
        {
          map((tag) => (
            <Tag.CheckableTag
              key={tag.id}
              checked={includes(tag, moduleTagsData.results)}
              onChange={handleChangeTag(tag)}
            >
              {tag.name?.ru}
            </Tag.CheckableTag>
          ))(tagsData.results)
        }
        <TagCreationFormDrawer />
      </Flex>
    </Spin>
  );
};

export default ModuleTags;
