import React from 'react';
import cn from 'classnames';

import styles from './PracticeTranslatesFormDrawer.module.scss';
import { Button, Drawer } from 'antd';
import useToggle from '../../../hooks/useToggle';
import { useForm } from 'react-hook-form';
import InputController from '../../shared/controllers/InputController';
import MarkdownInputController from '../../shared/controllers/MarkdownInputController';
import { useAppendTranslateMutation } from '../../../store/api/endpoints/lessonPractice';

const PracticeTranslatesFormDrawer = ({
  btnText,
  languageKey,
  practiceId,
  initialValues,
}) => {
  const [open, openDrawer, closeDrawer] = useToggle(false);

  const [
    appendTranslate,
    { isLoading: isAppending },
  ] = useAppendTranslateMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: initialValues,
  });

  const handleSubmit = async (values) => {
    try {
      await appendTranslate({ id: practiceId, languageKey, data: values }).unwrap();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer}>
        {btnText}
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Переводимые поля практики"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <form onSubmit={submit(handleSubmit)}>
          <MarkdownInputController control={control} name="answer" label="Ответ" />
          <MarkdownInputController control={control} name="help" label="Подсказка" />
          <MarkdownInputController control={control} name="description" label="Описание" />
          <Button block htmlType="submit" type="primary" loading={isAppending}>Сохранить</Button>
        </form>
      </Drawer>
    </>
  );
};

export default PracticeTranslatesFormDrawer;
