import React from 'react';
import { useGetModulesQuery } from '../../../store/api/endpoints/modules';
import TableWrapper from '../../shared/TableWrapper';
import useQueryString from '../../../hooks/useQueryString';
import usePaginationQueries from '../../../hooks/usePaginationQueries';
import { formatDate } from '../../../dateUtils';
import { Tag } from 'antd';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';

const ModuleList = () => {
  const query = useQueryString();
  const { page, pageSize } = usePaginationQueries();
  const navigate = useNavigate();

  const {
    data: moduleData,
    isLoading: isModuleDataLoading,
    isFetching: isModuleDataFetching,
  } = useGetModulesQuery({ ...query, page, pageSize });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Название',
      dataIndex: 'sysName',
      key: 'sysName',
      render: (name) => <Tag color="blue">{name}</Tag>
    },
    {
      title: 'Дата создания',
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: 'Дата редактирования',
      dataIndex: 'editedTime',
      key: 'editedTime',
      render: (date) => formatDate(date, true),
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
    },
    {
      title: 'Бесплатный?',
      dataIndex: 'isFree',
      key: 'isFree',
      render: (isFree) => isFree ? '✅' : '❌',
    },
    {
      title: 'Опубликован?',
      dataIndex: 'isPublished',
      key: 'isPublished',
      render: (isPublished) => isPublished ? '✅' : '❌',
    },
    {
      title: 'Удален?',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      render: (isDeleted) => isDeleted ? '✅' : '❌',
    },
  ];

  return (
    <TableWrapper
      data={moduleData?.results}
      total={moduleData?.count}
      columns={columns}
      loading={isModuleDataLoading || isModuleDataFetching}
      rowKey="id"
      onRow={(data) => ({
        onClick() {
          navigate(routes.modules(data.id));
        },
      })}
    />
  );
};

export default ModuleList;
