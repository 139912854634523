import { filter, identity, intersection, join, pipe } from 'ramda';
import dayjs from 'dayjs';
import queryString from 'query-string';

export const joinExisting = (arr, separator = '/') => pipe(
  filter(identity),
  join(separator),
)(arr);

export const hasAny = (list1, list2) => {
  const intersected = intersection(list1, list2);
  return intersected.length > 0;
}

export const mapToSelectOptions = (list, valueField = 'id', labelField = 'sysName') => {
  if (!list) return [];
  return list.map((item) => ({
    value: item[valueField],
    label: item[labelField],
  }))
};

export const formatDate = (date, withTime) => {
  if (!date) return null;
  const dateObj = dayjs(date);
  if (!dateObj.isValid()) return null;
  return withTime
    ? dateObj.format('DD.MM.YYYY (HH:mm)')
    : dateObj.format('DD.MM.YYYY');
};

export const truncate = (str, maxLength) => str.length > maxLength ? str.slice(0, maxLength - 3) + '...' : str;

const formatter = new Intl.NumberFormat('ru-RU');
export const formatNumber = (number) => {
  if (number === 0) return String(number);
  if (!number || Number.isNaN(number)) {
    return null;
  }
  if (Number.isNaN(Number(number))) return number;

  return formatter.format(number);
};

export const parseQuery = (link) => {
  const { url, query } = queryString.parseUrl(link);
  return query;
}
