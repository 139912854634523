import React from 'react';
import cn from 'classnames';

import styles from './CreateTariffDrawer.module.scss';
import useToggle from '../../../hooks/useToggle';
import { Button, Drawer } from 'antd';
import TariffMainForm from '../TariffMainForm';
import { useCreateTariffMutation } from '../../../store/api/endpoints/tariffs';
import { toast } from 'react-toastify';

const CreateTariffDrawer = () => {
  const [open, openDrawer, closeDrawer] = useToggle();

  const [
    createTariff,
    { isLoading: isCreating },
  ] = useCreateTariffMutation();

  const handleSubmit = async (values) => {
    try {
      await createTariff(values).unwrap();
      closeDrawer();
      toast.success('Тариф создан');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button type="primary" onClick={openDrawer}>
        Создать
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать тариф"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <TariffMainForm
          onSubmit={handleSubmit}
          loading={isCreating}
        />
      </Drawer>
    </>
  );
};

export default CreateTariffDrawer;
