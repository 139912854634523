import React from 'react';
import { Button, Flex } from 'antd';
import {
  useAppendTranslatesToLessonQuestionMutation,
  useCreateTestQuestionMutation, useEditTestQuestionMutation,
  useGetQuestionTypesQuery
} from '../../../store/api/endpoints/lessonTests';
import { useForm } from 'react-hook-form';
import SelectController from '../../shared/controllers/SelectController';
import InputController from '../../shared/controllers/InputController';
import InputNumberController from '../../shared/controllers/InputNumberController';
import CheckableTagsController from '../../shared/controllers/CheckableTagsController';
import { LANGUAGES } from '../../../constants';
import MarkdownInputController from '../../shared/controllers/MarkdownInputController';
import { array, number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';

const questionSchema = object({
  availableLanguages: array().required().min(0),
  questionType: number().required(),
  placeInOrder: number().required(),
  translates: object({
    ru: object({
      text: string().required(),
      comment: string().required(),
    }),
    uz: object({
      text: string().required(),
      comment: string().required(),
    }),
  }),
});

const QuestionForm = ({
  mode,
  initialValues,
  onSuccess,
}) => {
  const {
    data: questionTypes,
    isFetching: isQuestionTypesFetching,
  } = useGetQuestionTypesQuery({ page: -1 });

  const [createTestQuestion, { isLoading: isCreatingTestQuestion }] = useCreateTestQuestionMutation();
  const [editTestQuestion, { isLoading: isEditingTestQuestion }] = useEditTestQuestionMutation();
  const [appendTranslate, { isLoading: isAppendingTranslate }] = useAppendTranslatesToLessonQuestionMutation();

  const {
    control,
    handleSubmit: submit,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(questionSchema),
  });

  const handleCreate = async (values) => {
    try {
      const {
        translates: {
          ru, uz,
        },
        ...questionBody
      } = values;

      const result = await createTestQuestion(questionBody).unwrap();
      await appendTranslate({ questionId: result.id, languageKey: 'ru', data: ru }).unwrap();
      await appendTranslate({ questionId: result.id, languageKey: 'uz', data: uz }).unwrap();
      toast.success('Создано');
      onSuccess();
    } catch (e) {

    }
  };

  const handleEdit = async (values) => {
    try {
      const {
        translates: {
          ru, uz,
        },
        ...questionBody
      } = values;

      const result = await editTestQuestion({ questionId: values.id, data: questionBody }).unwrap();
      await appendTranslate({ questionId: result.id, languageKey: 'ru', data: ru }).unwrap();
      await appendTranslate({ questionId: result.id, languageKey: 'uz', data: uz }).unwrap();
      toast.success('Создано');
      onSuccess();
    } catch (e) {

    }
  };

  const operations = {
    CREATE: submit(handleCreate),
    EDIT: submit(handleEdit),
  };

  return (
    <form
      onSubmit={operations[mode]}
    >

      <CheckableTagsController control={control} name="availableLanguages" label="Доступен на языках" options={LANGUAGES} />
      <SelectController
        control={control}
        name="questionType"
        options={questionTypes?.results}
        label="Тип вопроса"
        loading={isQuestionTypesFetching}
      />
      <InputController control={control} name="sysName" label="Системное имя" />
      <InputNumberController control={control} name="placeInOrder" label="Порядковый номер" />
      <Flex gap={12}>
        <div style={{ width: '50%' }}>
          <MarkdownInputController control={control} name="translates.ru.text" label="Вопрос на русском" />
        </div>
        <div style={{ width: '50%' }}>
          <MarkdownInputController control={control} name="translates.uz.text" label="Вопрос на узбекском" />
        </div>
      </Flex>
      <Flex gap={12}>
        <div style={{ width: '50%' }}>
          <MarkdownInputController control={control} name="translates.ru.comment" label="Комментарий на русском" />
        </div>
        <div style={{ width: '50%' }}>
          <MarkdownInputController control={control} name="translates.uz.comment" label="Комментарий на узбекском" />
        </div>
      </Flex>
      <Button
        type="primary"
        htmlType="submit"
        block
        loading={isCreatingTestQuestion || isAppendingTranslate}
      >
        Сохранить
      </Button>
    </form>
  );
};

export default QuestionForm;
