import React from 'react';
import cn from 'classnames';

import styles from './EditTariffDrawer.module.scss';
import { Button, Drawer } from 'antd';
import useToggle from '../../../hooks/useToggle';
import TariffMainForm from '../TariffMainForm';
import { useEditTariffMutation, useGetTariffDetailsQuery } from '../../../store/api/endpoints/tariffs';

const EditTariffDrawer = ({ tariffId }) => {
  const [open, openDrawer, closeDrawer] = useToggle(false);

  const {
    data: tariff,
    isLoading: isTariffLoading,
  } = useGetTariffDetailsQuery(tariffId);

  const [
    editTariff,
    { isLoading: isEditing },
  ] = useEditTariffMutation();

  const handleSubmit = async (values) => {
    try {
      await editTariff({ tariffId, data: values }).unwrap();
      closeDrawer();
    } catch (e) {

    }
  }

  return (
    <>
      <Button
        type="primary"
        style={{ marginTop: 20 }}
        onClick={openDrawer}
      >
        Редактировать
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Редактировать тариф"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <TariffMainForm
          initialValues={tariff}
          onSubmit={handleSubmit}
          loading={isEditing}
        />
      </Drawer>
    </>
  );
};

export default EditTariffDrawer;
