import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from './store';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { cssTransition, ToastContainer } from 'react-toastify';

import './global.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-toastify/dist/ReactToastify.min.css';

const container = document.getElementById('root');
const root = createRoot(container);

const toastAnimation = cssTransition({
  enter: 'scale-in-tr',
  exit: 'scale-out-tr',
})

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
      <ToastContainer
        hideProgressBar
        limit={5}
        autoClose={6000}
        transition={toastAnimation}
      />
    </Provider>
  </React.StrictMode>
);
