import React from 'react';
import { useController } from 'react-hook-form';
import { Flex, Switch, Typography } from 'antd';

const SwitchController = ({
  control,
  name,
  label,
  checkedChildren,
  unCheckedChildren,
  justify = 'end',
}) => {
  const { field, fieldState } = useController({ control, name });

  return (
    <div>
      <Typography.Text type={fieldState.error ? 'danger' : 'secondary'}>
        {label}
        {' '}
        {fieldState.error ? fieldState.error.message : null}
      </Typography.Text>
      <Flex justify={justify}>
        <Switch
          checked={field.value}
          onChange={field.onChange}
          checkedChildren="✅"
          unCheckedChildren="❌"
        />
      </Flex>
    </div>
  );
};

export default SwitchController;
