import React from 'react';
import cn from 'classnames';

import styles from './DeleteQuestion.module.scss';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { Button, Popconfirm } from 'antd';
import { useDeleteTestQuestionMutation } from '../../../store/api/endpoints/lessonTests';
import { toast } from 'react-toastify';

const DeleteQuestion = ({ questionId }) => {
  const [deleteQuestion, { isLoading }] = useDeleteTestQuestionMutation();

  const handleOk = async () => {
    try {
      await deleteQuestion(questionId).unwrap();
      toast.success('Удалено');
    } catch (e) {

    }
  };

  return (
    <Popconfirm
      onConfirm={handleOk}
      title="Вы уверены?"
      okText="Да"
      cancelText="Отменить"
      okButtonProps={{
        loading: isLoading,
      }}
    >
      <Button type="text" icon={<FiTrash2 />} danger />
    </Popconfirm>
  );
};

export default DeleteQuestion;
