
import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getLessonPractices: build.query({
      query: (params) => ({
        url: routes.lessonPractices(),
        params,
      }),
      providesTags: ['LESSON_PRACTICES'],
    }),
    getLessonPracticeDetails: build.query({
      query: (id) => ({
        url: routes.lessonPractices(id),
      }),
      providesTags: ['LESSON_PRACTICE_DETAILS'],
    }),
    createLessonPractice: build.mutation({
      query: (data) => ({
        url: routes.lessonPractices(),
        data,
        method: 'POST'
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_PRACTICE_DETAILS', 'LESSON_PRACTICES'] }),
    }),
    editLessonPractice: build.mutation({
      query: ({ id, data }) => ({
        url: routes.lessonPractices(id),
        data,
        method: 'PUT'
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_PRACTICE_DETAILS', 'LESSON_PRACTICES'] }),
    }),

    getTrainerTemplates: build.query({
      query: (params) => ({
        url: routes.trainerTemplates(),
        params,
      }),
      providesTags: ['TRAINER_TEMPLATES'],
    }),

    publishLessonPractice: build.mutation({
      query: (id) => ({
        url: routes.lessonPracticePublication(id),
        method: 'PUT'
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_PRACTICE_DETAILS', 'LESSON_PRACTICES'] }),
    }),
    unpublishLessonPractice: build.mutation({
      query: (id) => ({
        url: routes.lessonPracticePublication(id),
        method: 'DELETE'
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_PRACTICE_DETAILS', 'LESSON_PRACTICES'] }),
    }),

    appendTranslate: build.mutation({
      query: ({ id, languageKey, data }) => ({
        url: routes.lessonPracticeTranslates(id, languageKey),
        data,
        method: 'PUT'
      }),
      invalidatesTags: invalidateOn({ success: ['LESSON_PRACTICE_DETAILS', 'LESSON_PRACTICES'] }),
    }),

  }),
});

export const {
  useGetLessonPracticesQuery,
  useGetLessonPracticeDetailsQuery,
  useCreateLessonPracticeMutation,
  useEditLessonPracticeMutation,

  useGetTrainerTemplatesQuery,

  usePublishLessonPracticeMutation,
  useUnpublishLessonPracticeMutation,

  useAppendTranslateMutation,
} = mainApi;
