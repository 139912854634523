import { mainApi } from '../index';
import routes from '../routes';
import { invalidateOn } from '../utils';
import { without } from 'ramda';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query({
      query: (params) => ({
        url: routes.tags(),
        params
      }),
      providesTags: ['TAGS'],
    }),
    addTagToModule: build.mutation({
      query: ({ tag, moduleId }) => ({
        url: routes.tagToModule(tag.id, moduleId),
        method: 'POST'
      }),
      onQueryStarted({ tag, moduleId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          mainApi.util.updateQueryData(
            'getTags',
            { page: -1, module: moduleId },
            (draft) => {
              draft.results.push(tag);
            }
          )
        )
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    removeTagFromModule: build.mutation({
      query: ({ tag, moduleId }) => ({
        url: routes.tagToModule(tag.id, moduleId),
        method: 'DELETE'
      }),
      onQueryStarted({ tag, moduleId }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          mainApi.util.updateQueryData(
            'getTags',
            { page: -1, module: moduleId },
            (draft) => {
              draft.results = without([tag], draft.results);
            }
          )
        )
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    createTag: build.mutation({
      query: (data) => ({
        url: routes.tags(),
        method: 'POST',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['TAGS'] })
    }),
    editTag: build.mutation({
      query: ({ tagId, data }) => ({
        url: routes.tags(tagId),
        method: 'PUT',
        data,
      }),
      invalidatesTags: invalidateOn({ success: ['TAGS'] })
    }),
  }),
});

export const {
  useGetTagsQuery,
  useAddTagToModuleMutation,
  useRemoveTagFromModuleMutation,
  useCreateTagMutation,
  useEditTagMutation,
} = mainApi;
