import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import QuestionForm from '../QuestionForm';
import { FiEdit2 } from 'react-icons/fi';
import { useLazyGetLessonQuestionDetailsQuery } from '../../../store/api/endpoints/lessonTests';

const EditQuestionFormDrawer = ({
  questionId,
}) => {
  const [open, setOpen] = useState(false);

  const [
    getLessonData,
    { data: questionData, isFetching },
  ] = useLazyGetLessonQuestionDetailsQuery();

  const openDrawer = async () => {
    await getLessonData(questionId);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <>
      <Button type="text" onClick={openDrawer} icon={<FiEdit2 />} loading={isFetching} />
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Редактировать вопрос"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <QuestionForm
          mode="EDIT"
          initialValues={{
            ...questionData,
            questionType: questionData?.questionType?.id,
          }}
          onSuccess={closeDrawer}
        />
      </Drawer>
    </>
  );
};

export default EditQuestionFormDrawer;
