import React from 'react';
import { useUnhideTopicMutation } from '../../../store/api/endpoints/topics';
import { Button } from 'antd';

const UnhideTopic = ({ id }) => {
  const [
    unhideTopic,
    { isLoading },
  ] = useUnhideTopicMutation();

  const handleClick = async () => {
    try {
      await unhideTopic(id).unwrap();
    } catch (e) {
      console.log(e);
    }
  }


  return (
    <Button
      type="text"
      onClick={handleClick}
      loading={isLoading}
    >
      Опубликовать
    </Button>
  );
};

export default UnhideTopic;
