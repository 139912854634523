import Tags from './Tags';
import routes from '../../routes';


const TagsRoute = {
  path: routes.tags(),
  element: <Tags />
};

export default TagsRoute;
