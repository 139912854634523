import React from 'react';
import { useController } from 'react-hook-form';
import { Tag, Typography } from 'antd';
import { includes, map, without } from 'ramda';

const CheckableTagsController = ({
  control,
  name,
  label,
  options = [],
}) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <div
      style={{ paddingBottom: 20, position: 'relative' }}
    >
      <div>
        <Typography.Text type={fieldState.error ? 'danger' : 'secondary'}>{label}</Typography.Text>
      </div>
      <div>
        {
          map(({ label, id }) => (
            <Tag.CheckableTag
              key={id}
              checked={field.value && includes(id, field.value)}
              onChange={() => {
                if (!field.value || field.value.length === 0) {
                  return field.onChange([id]);
                }
                if (includes(id, field.value)) {
                  field.onChange(without([id], field.value));
                } else {
                  field.onChange([...field.value, id]);
                }
              }}
            >
              {label}
            </Tag.CheckableTag>
          ))(options)
        }
      </div>
      <Typography.Text
        type="danger"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          textAlign: 'right',
        }}
      >
        {fieldState.error ? fieldState.error.message : ''}
      </Typography.Text>
    </div>
  )
};

export default CheckableTagsController;
