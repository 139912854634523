import React from 'react';
import cn from 'classnames';

import styles from './Users.module.scss';
import { Flex, Typography } from 'antd';
import CreateUserFormDrawer from '../../../containers/users/CreateUserFormDrawer';
import UsersList from '../../../containers/users/UsersList';

const Users = () => {

  return (
    <div className="paper">
      <Flex justify="space-between">
        <Typography.Title level={4} style={{ marginTop: 0 }}>Пользователи</Typography.Title>
        <CreateUserFormDrawer />
      </Flex>
      <div>
        <UsersList />
      </div>
    </div>
  );
};

export default Users;
