import React from 'react';
import cn from 'classnames';

import styles from './ModulePublicationStatusForm.module.scss';
import {
  useGetModuleDetailsQuery,
  usePublishModuleMutation,
  useUnpublishModuleMutation
} from '../../../store/api/endpoints/modules';
import { Button, Flex, Spin, Tag, Typography } from 'antd';
import { toast } from 'react-toastify';

const ModulePublicationStatusForm = ({ moduleId }) => {
  const {
    data: module,
    isLoading: isModuleLoading,
  } = useGetModuleDetailsQuery(moduleId);

  const [
    publishModule,
    { isLoading: isPublishing },
  ] = usePublishModuleMutation();
  const [
    unpublishModule,
    { isLoading: isUnpublishing },
  ] = useUnpublishModuleMutation();

  const handleClickPublish = async () => {
    try {
      await publishModule(moduleId).unwrap();
      toast.success('Модуль опубликован');
    } catch (e) {
    }
  };

  const handleClickUnpublish = async () => {
    try {
      await unpublishModule(moduleId).unwrap();
      toast.success('Модуль снят с публикации');
    } catch (e) {
    }
  };

  if (isModuleLoading) return <Spin />;

  const style = { marginTop: 0 };

  return module?.isPublished
    ? (
      <Flex gap={10}>
        <Typography.Title type="success" level={3} style={style}>Опубликован</Typography.Title>
        <Button type="primary" danger onClick={handleClickUnpublish} loading={isUnpublishing}>
          Снять публикацию
        </Button>
      </Flex>
    )
    : (
      <Flex gap={10}>
        <Typography.Title type="danger" level={4} style={style}>Не опубликован</Typography.Title>
        <Button type="primary" onClick={handleClickPublish} loading={isPublishing}>
          Опубликовать
        </Button>
      </Flex>
    )
};

export default ModulePublicationStatusForm;
