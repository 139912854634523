import React from 'react';
import cn from 'classnames';

import styles from './TagDetails.module.scss';
import { Empty, Typography } from 'antd';
import { useSelectedTag } from '../../../store/slicers/tagsSlice';
import TagMainForm from '../TagMainForm';
import { useEditTagMutation } from '../../../store/api/endpoints/tags';
import { toast } from 'react-toastify';

const TagDetails = () => {
  const selectedTag = useSelectedTag();

  const [
    editTag,
    { isLoading: isEditingTag },
  ] = useEditTagMutation();

  const handleSubmit = async (data) => {
    try {
      await editTag({ tagId: selectedTag.id, data }).unwrap();
      toast.success('Тэг сохранен');
    } catch (e) {

    }
  };

  if (!selectedTag) return <Empty description="Выберите Тэг для просмотра деталей" />

  return (
    <div className="paper">
      <Typography.Title level={4} style={{ marginTop: 0 }}>Детали</Typography.Title>
      <TagMainForm
        initialValues={selectedTag}
        onSubmit={handleSubmit}
        isLoading={isEditingTag}
      />
    </div>
  );
};

export default TagDetails;
