import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { rtkQueryErrorLogger } from './api/middlewares/rtkQueryErrorHandler';

import { mainApi } from './api';

import moduleSlice from './slicers/moduleSlice';
import tagsSlice from './slicers/tagsSlice';
import lessonsSlice from './slicers/lessonsSlice';
import lessonTheorySlice from './slicers/lessonTheorySlice';
import topicsSlice from './slicers/topicsSlice';

const rootReducer = combineReducers({
  [mainApi.reducerPath]: mainApi.reducer,
  [moduleSlice.name]: moduleSlice.reducer,
  [tagsSlice.name]: tagsSlice.reducer,
  [lessonsSlice.name]: lessonsSlice.reducer,
  [lessonTheorySlice.name]: lessonTheorySlice.reducer,
  [topicsSlice.name]: topicsSlice.reducer,
});

const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
    .concat(rtkQueryErrorLogger)
    .concat(mainApi.middleware)
});

export default store;
