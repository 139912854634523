import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import styles from './EditAnswerFormDrawer.module.scss';
import { useNavigate } from 'react-router';
import { Button, Drawer } from 'antd';
import AnswerForm from '../AnswerForm';
import { FiEdit2 } from 'react-icons/fi';
import { useLazyGetTestAnswerDetailsQuery } from '../../../store/api/endpoints/lessonTests';

const EditAnswerFormDrawer = ({ answerId }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [
    getAnswer,
    { isFetching, data: answer },
  ] = useLazyGetTestAnswerDetailsQuery();

  const openDrawer = async () => {
    await getAnswer(answerId);
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };


  return (
    <>
      <Button
        type="text"
        onClick={openDrawer}
        icon={<FiEdit2/>}
        loading={isFetching}
      />
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Редактировать ответ"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <AnswerForm
          mode="EDIT"
          initialValues={answer}
          onSuccess={() => {
            closeDrawer();
          }}
        />
      </Drawer>
    </>
  );
};

export default EditAnswerFormDrawer;
