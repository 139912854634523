import React, { useState } from 'react';
import cn from 'classnames';

import styles from './TagCreationFormDrawer.module.scss';
import { Button, Drawer } from 'antd';
import TagMainForm from '../TagMainForm';
import { useCreateTagMutation } from '../../../store/api/endpoints/tags';
import { toast } from 'react-toastify';

const TagCreationFormDrawer = () => {
  const [open, setOpen] = useState();

  const [createTag, { isLoading: isCreatingTag }] = useCreateTagMutation();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleSubmit = async (data) => {
    try {
      await createTag(data).unwrap();
      toast.success('Тэг создан');
      closeDrawer();
    } catch (e) {

    }
  };

  return (
    <>
      <Button
        onClick={openDrawer}
        type="primary"
      >
        Создать
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать тэг"
        destroyOnClose
        maskClosable={false}
        width={600}
      >
        <TagMainForm
          onSubmit={handleSubmit}
          isLoading={isCreatingTag}
        />
      </Drawer>
    </>
  );
};

export default TagCreationFormDrawer;
