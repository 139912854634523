import React from 'react';
import { Select, Typography } from 'antd';
import { useController } from 'react-hook-form';

const SelectController = ({
  control,
  name,
  options = [],
  label,
  ...selectProps
}) => {
  const { field, fieldState } = useController({
    control, name,
  });

  const preparedOptions = options.map((opt) => {
    let label = '';
    let value = '';

    if (opt.sysName) label = opt.sysName;
    else if (opt.name) label = opt.name.ru;

    if (opt.id) value = opt.id;

    return { value, label };
  });

  return (
    <div>
      <div>
        <Typography.Text type={fieldState.error ? 'danger' : 'secondary'}>{label}</Typography.Text>
      </div>
      <div style={{ paddingBottom: 20, position: 'relative' }}>
        <Select
          style={{ width: '100%' }}
          value={field.value}
          options={preparedOptions}
          optionFilterProp="children"
          onChange={field.onChange}
          status={fieldState.error ? 'error' : null}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          {...selectProps}
        />
        {
          fieldState.error && (
            <Typography.Text
              type="danger"
              style={{ position: 'absolute', bottom: 0, left: 0, right: 0, textAlign: 'right' }}
            >
              {fieldState.error.message}
            </Typography.Text>
          )
        }
      </div>
    </div>
  );
};

export default SelectController;
