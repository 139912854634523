import React, { useState } from 'react';
import cn from 'classnames';

import styles from './CreatePracticeDrawer.module.scss';
import { Button, Drawer } from 'antd';
import PracticeMainForm from '../PracticeMainForm';
import { useCreateLessonPracticeMutation } from '../../../store/api/endpoints/lessonPractice';

const CreatePracticeDrawer = ({ lessonId }) => {
  const [open, setOpen] = useState(false);

  const [
    createPractice,
    {
      isLoading: isCreating,
    }
  ] = useCreateLessonPracticeMutation();

  const openDrawer = () => {
    setOpen(true);
  };

  const closeDrawer = () => {
    setOpen(false);
  };

  const handleSubmit = async (values) => {
    try {
      await createPractice(values).unwrap();
      closeDrawer();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Button
        type="primary"
        onClick={openDrawer}
      >
        Создать
      </Button>
      <Drawer
        open={open}
        onClose={closeDrawer}
        placement="right"
        title="Создать практику"
        destroyOnClose
        maskClosable={false}
        width="90%"
      >
        <PracticeMainForm
          loading={isCreating}
          initialValues={{ lesson: lessonId }}
          onSubmit={handleSubmit}
        />
      </Drawer>
    </>
  );
};

export default CreatePracticeDrawer;
